import React from "react";
import { Image } from 'react-bootstrap';
import env from '../../env.json';
import GIA from '../../images/GIA.png';
import IGI from '../../images/IGI.png';
import HRD from '../../images/HRD.png';

const LabComponent = props => {

    switch(props.lab) {
        case "IGI":
            return <Image src={IGI} fluid />;
        case "GIA":
            return <Image src={GIA} fluid />;
        case "HRD":
            return <Image src={HRD} fluid />;
        default:
            return null;
    }
}

export default LabComponent;