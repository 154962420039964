import "./diamond-finder.css";
import Filters from "../components/filter/filters";

import DiamondListing from "../components/listings/diamondListing";
import axios from "axios";
import env from "../env.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import {
  changeComponent,
  restartCustomRing,
  addCustomDesignRingValues,
} from "../redux/actions/diamondAction";
import indFunction from "../components/common/IndFunctions";
import { useEffect, useState } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import Budget from "../components/custom-design/budget";
import CenterDiamondShape from "../components/custom-design/centerDiamondShape";
import CenterShappireShape from "../components/custom-design/centerShappireShape";
import ChooseCenterDiamond from "../components/custom-design/chooseCenterDiamond";
import ChooseMetal from "../components/custom-design/chooseMetal";
import ChooseStyle from "../components/custom-design/chooseStyle";
import DesignInspiration from "../components/custom-design/designInspiration";
import DiamondSize from "../components/custom-design/diamondSize";
import FirstComponent from "../components/custom-design/firstComponent";
import ShappireSize from "../components/custom-design/shappireSize";
import SpecialDay from "../components/custom-design/specialDay";
import ThankYou from "../components/custom-design/thankYou";
import WhoWill from "../components/custom-design/whoWill";
import YourDetails from "../components/custom-design/yourDetails";
import FormData from "form-data";

import CustomImage from "../components/custom-design/customImage";

export default function CustomDesignRing() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  useEffect(() => {}, []);

  return (
    <div className="full-height-sec">
      <div className="container-fluid">
        {/* custom design feature */}
        <Row>
          <Col xs={4} md={4} xs={4} id="bg-brand-custom">
            <CustomImage />
          </Col>
          <Col xs={8} md={8} xs={8} id="full-column-content">
            <div className="f-height-sec">
              <div className="popup-full-content">
                <div className="middle-content-popup">
                  {state.customDesign.currentComponent == "budget" && (
                    <Budget />
                  )}{" "}
                  {/*then this page will be 8 */}
                  {state.customDesign.currentComponent ==
                    "centerDiamondShape" && <CenterDiamondShape />}{" "}
                  {/* if center stone is diamond - then this page will be 4*/}
                  {state.customDesign.currentComponent ==
                    "centerShappireShape" && <CenterShappireShape />}{" "}
                  {/*if center stone is shappire - then this page will be 4*/}
                  {state.customDesign.currentComponent ==
                    "chooseCenterDiamond" && <ChooseCenterDiamond />}{" "}
                  {/*3*/}
                  {state.customDesign.currentComponent == "chooseMetal" && (
                    <ChooseMetal />
                  )}{" "}
                  {/*then this page will be 7*/}
                  {state.customDesign.currentComponent == "chooseStyle" && (
                    <ChooseStyle />
                  )}{" "}
                  {/* then this page will be 6*/}
                  {state.customDesign.currentComponent ==
                    "designInspiration" && <DesignInspiration />}{" "}
                  {/*if center stone is diamond - then this page will be 10*/}
                  {state.customDesign.currentComponent == "diamondSize" && (
                    <DiamondSize />
                  )}{" "}
                  {/* if center stone is diamond - then this page will be 5*/}
                  {state.customDesign.currentComponent == "firstComponent" && (
                    <FirstComponent />
                  )}{" "}
                  {/* 1*/}
                  {state.customDesign.currentComponent == "shappireSize" && (
                    <ShappireSize />
                  )}{" "}
                  {/*if center stone is shappire - then this page will be 5*/}
                  {state.customDesign.currentComponent == "specialDay" && (
                    <SpecialDay />
                  )}{" "}
                  {/*then this page will be 9*/}
                  {state.customDesign.currentComponent == "yourDetails" && (
                    <YourDetails sendData={sendData} />
                  )}{" "}
                  {/*then this page will be 11*/}
                  {state.customDesign.currentComponent == "thankYou" && (
                    <ThankYou />
                  )}{" "}
                  {/*then this page will be 12*/}
                  {state.customDesign.currentComponent == "whoWill" && (
                    <WhoWill />
                  )}{" "}
                  {/*2*/}
                </div>
                
              </div>

              <Row className="bottom-sticky-btn">
                <Col className="btn-equal">
                  <button onClick={backButtonCalled} className="prev-next-btn">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="14"
                      viewBox="0 0 15 14"
                      fill="none"
                    >
                      <path
                        d="M7.85 1L2 7L7.85 13M15 7H2H15Z"
                        stroke="#002A35"
                        stroke-width="1.5"
                      />
                    </svg>
                    Previous
                  </button>
                  <button onClick={restartApp}>
                    Restart
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <path
                        d="M14.0889 3.37769V6.93324H10.5333"
                        stroke="#3A3A3A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M12.5714 9.77658C12.1784 10.8669 11.4346 11.8025 10.4519 12.4424C9.46925 13.0822 8.30105 13.3917 7.12333 13.3242C5.94561 13.2567 4.82219 12.8158 3.92236 12.0679C3.02252 11.3201 2.39502 10.3059 2.13442 9.1781C1.87382 8.0503 1.99423 6.87003 2.47751 5.81514C2.96079 4.76026 3.78076 3.88791 4.81385 3.32956C5.84694 2.7712 7.03719 2.55709 8.20523 2.71949C9.37326 2.88188 10.4558 3.41199 11.2897 4.22992L14.0889 6.81362"
                        stroke="#3A3A3A"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </button>
                  {state.customDesign.currentComponent != "yourDetails" && (
                    <button
                      onClick={nextButtonCalled}
                      className="prev-next-btn nxt-b"
                    >
                      Next
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="14"
                        viewBox="0 0 15 14"
                        fill="none"
                      >
                        <path
                          d="M7.15 1L13 7L7.15 13M0 7H13H0Z"
                          stroke="#002A35"
                          stroke-width="1.5"
                        />
                      </svg>
                    </button>
                  )}
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
  function sendData() {
    const options = {
      method: "POST",
      url: env.customDesign,
      data: state.customDesign,
    };
    axios.defaults.withCredentials = true;
    axios(options)
      .then((res) => {
        console.log("data send");
      })
      .catch((error) => {
        console.log("Data fetch error... " + error);
      });
  }
  function restartApp() {
    dispatch(restartCustomRing());
  }
  function nextButtonCalled() {
    if (
      state.customDesign.currentComponent == "chooseMetal" &&
      state.customDesign.metal &&
      state.customDesign.metalColor
    ) {
      dispatch(changeComponent("budget"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "chooseCenterDiamond" &&
      state.customDesign.centerDiamond == "diamond"
    ) {
      dispatch(changeComponent("centerDiamondShape"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "chooseCenterDiamond" &&
      state.customDesign.centerDiamond == "shappire"
    ) {
      dispatch(changeComponent("centerShappireShape"));
      return 0;
    }
    if (state.customDesign.currentComponent == "whoWill") {
      dispatch(changeComponent("chooseCenterDiamond"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "chooseStyle" &&
      state.customDesign.style
    ) {
      dispatch(changeComponent("chooseMetal"));
      return 0;
    }
    if (
      (state.customDesign.currentComponent == "diamondSize" &&
        state.customDesign.caratSize &&
        state.customDesign.colorRange &&
        state.customDesign.diamondQuality) ||
      (state.customDesign.currentComponent == "shappireSize" &&
        state.customDesign.shappireColor &&
        state.customDesign.shappireCarat &&
        state.customDesign.shappireQuality)
    ) {
      dispatch(changeComponent("chooseStyle"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "specialDay" &&
      state.customDesign.specialDay
    ) {
      dispatch(changeComponent("designInspiration"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "centerDiamondShape" &&
      state.customDesign.diamondShape
    ) {
      dispatch(changeComponent("diamondSize"));
      return 0;
    }

    if (
      state.customDesign.currentComponent == "centerShappireShape" &&
      state.customDesign.shappireShape
    ) {
      dispatch(changeComponent("shappireSize"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "budget" &&
      state.customDesign.centerStoneBudgetMin &&
      state.customDesign.centerStoneBudgetMax &&
      state.customDesign.ringSettingBudgetMin &&
      state.customDesign.ringSettingBudgetMax
    ) {
      dispatch(changeComponent("specialDay"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "designInspiration" &&
      state.customDesign.designInspiration
    ) {
      console.log(state.customDesign.designFiles, "design files");
      for (let i = 0; i < state.customDesign.designFiles.length; i++) {
        var bodyFormData = new FormData();
        bodyFormData.append(
          "file",
          state.customDesign.designFiles[i].originFileObj
        );
        axios({
          method: "post",
          url: env.inspirationMediaUpload,
          data: bodyFormData,
          headers: { "Content-Type": "multipart/form-data" },
        }).then(function (response) {
          //handle success
          dispatch(
            addCustomDesignRingValues({
              inpirationURLs: response.data.attachment_url,
            })
          );
        });
      }

      dispatch(changeComponent("yourDetails"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "yourDetails" &&
      state.customDesign.name &&
      state.customDesign.emailID &&
      state.customDesign.mobileNo &&
      state.customDesign.whatsappNo &&
      state.customDesign.ringSize
    ) {
      dispatch(changeComponent("thankYou"));
      return 0;
    }
    if (state.customDesign.currentComponent == "firstComponent") {
      dispatch(changeComponent("whoWill"));
      return 0;
    }
  }
  function backButtonCalled() {
    if (state.customDesign.currentComponent == "specialDay") {
      dispatch(changeComponent("budget"));
      return 0;
    }
    if (state.customDesign.currentComponent == "diamondSize") {
      dispatch(changeComponent("centerDiamondShape"));
      return 0;
    }
    if (state.customDesign.currentComponent == "shappireSize") {
      dispatch(changeComponent("centerShappireShape"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "centerDiamondShape" ||
      state.customDesign.currentComponent == "centerShappireShape"
    ) {
      dispatch(changeComponent("chooseCenterDiamond"));
      return 0;
    }
    if (state.customDesign.currentComponent == "budget") {
      dispatch(changeComponent("chooseMetal"));
      return 0;
    }
    if (state.customDesign.currentComponent == "chooseMetal") {
      dispatch(changeComponent("chooseStyle"));
      return 0;
    }
    if (state.customDesign.currentComponent == "yourDetails") {
      dispatch(changeComponent("designInspiration"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "chooseStyle" &&
      state.customDesign.centerDiamond == "diamond"
    ) {
      dispatch(changeComponent("diamondSize"));
      return 0;
    }
    if (
      state.customDesign.currentComponent == "chooseStyle" &&
      state.customDesign.centerDiamond == "shappire"
    ) {
      dispatch(changeComponent("shappireSize"));
      return 0;
    }
    if (state.customDesign.currentComponent == "designInspiration") {
      dispatch(changeComponent("specialDay"));
      return 0;
    }
    if (state.customDesign.currentComponent == "thankYou") {
      dispatch(changeComponent("yourDetails"));
      return 0;
    }
    if (state.customDesign.currentComponent == "chooseCenterDiamond") {
      dispatch(changeComponent("whoWill"));
      return 0;
    }
    if (state.customDesign.currentComponent == "whoWill") {
      dispatch(changeComponent("firstComponent"));
      return 0;
    }
  }
}
