import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { addCustomDesignRingValues, removeCustomDesignRingValues } from "../../redux/actions/diamondAction";
export default function WhoWill() {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();

    const shape = [{
        name: "Round"
    },
    {
        name: "Princes"
    },
    {
        name: "Cushion"
    },
    {
        name: "Oval"
    },
    {
        name: "Asscher"
    }];
    const shapeHTML = shape.map(i => (
        <span >
            <input
                type="radio"
                name="shape"
                id={`shape-${i.name}`}
                checked={state.customDesign.shappireShape == i.name}
                className="input-hidden"
                onChange={(e) => {
                    if (e.target.checked) {
                        dispatch(addCustomDesignRingValues({ shappireShape: i.name }))
                    } else {
                        dispatch(removeCustomDesignRingValues({ shappireShape: i.name }))
                    }
                }}
            />
            <label htmlFor={`shape-${i.name}`} className="diamond-mr-20 border-square">
                <Image height="66px" width="66px" src="https://sarvada.wpstagingserver.com/wp-content/uploads/2023/06/Round.png" alt="Diamond" />
                <div class="cap-letter">{i.name}</div>
            </label>
        </span>
    ))
    return (

        <Row>
            <div className="text-center">
      <h1>Center Shappire Shape</h1>
      </div>    
      <div className="shape-selct text-center">         
                {shapeHTML}
                </div>   
        </Row>

    );
}
