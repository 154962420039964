import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  addCustomDesignRingValues,
  removeCustomDesignRingValues,
} from "../../redux/actions/diamondAction";
export default function WhoWill() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  return (
    <>
      <div className="budget-width-fix">
        <Row>
          <Col>
            <div className="text-center">
              <h1>Budget</h1>
              <p className="sub-budget">Budget Just give us an idea of your ideal spend</p>
            </div>
          </Col>
        </Row>
        <Row className="one-linestone">
          <Col md={3}>
            <div className="lablename-budget">Center Stone</div>
          </Col>
          <Col md={4} className="price-down-stone">
            <select name="cars" id="cars">
              <option value="other">INR</option>
              <option value="me">USD</option>
            </select>
            <input
              type="number"
              value={state.customDesign.centerStoneBudgetMin}
              placeholder="Min Center Store"
              onChange={(e) =>
                dispatch(
                  addCustomDesignRingValues({
                    centerStoneBudgetMin: e.target.value,
                  })
                )
              }
            />
          </Col>
          <Col md={1} className="text-center">
            to
          </Col>
          <Col md={4} className="price-up-stone">
            {" "}
            <select name="cars" id="cars">
              <option value="other">INR</option>
              <option value="me">USD</option>
            </select>
            <input
              type="number"
              placeholder="Max Center Store"
              value={state.customDesign.centerStoneBudgetMax}
              onChange={(e) =>
                dispatch(
                  addCustomDesignRingValues({
                    centerStoneBudgetMax: e.target.value,
                  })
                )
              }
            />
          </Col>
        </Row>
        <Row className="one-linesetting">
          <Col md={3}><div className="lablename-budget">Ring Setting</div></Col>
          <Col md={4} className="price-down-stone">
            {" "}
            <select name="cars" id="cars">
              <option value="other">INR</option>
              <option value="me">USD</option>
            </select>
            <input
              type="number"
              placeholder="Min Ring Store"
              value={state.customDesign.ringSettingBudgetMin}
              onChange={(e) =>
                dispatch(
                  addCustomDesignRingValues({
                    ringSettingBudgetMin: e.target.value,
                  })
                )
              }
            />
          </Col>
          <Col md={1} className="text-center">
            to
          </Col>
          <Col md={4} className="price-up-stone">
            {" "}
            <select name="cars" id="cars">
              <option value="other">INR</option>
              <option value="me">USD</option>
            </select>
            <input
              type="number"
              placeholder="Min Ring Store"
              value={state.customDesign.ringSettingBudgetMax}
              onChange={(e) =>
                dispatch(
                  addCustomDesignRingValues({
                    ringSettingBudgetMax: e.target.value,
                  })
                )
              }
            />
          </Col>
        </Row>
        <Row>
          <span>
            <input
              type="radio"
              name="shape"
              id={`notSure`}
              checked={state.customDesign.centerStoneBudgetMin == "notSure"}
              className="input-hidden"
              onChange={(e) => {
                if (e.target.checked) {
                  dispatch(
                    addCustomDesignRingValues({
                      centerStoneBudgetMin: "notSure",
                    })
                  );
                  dispatch(
                    addCustomDesignRingValues({
                      centerStoneBudgetMax: "notSure",
                    })
                  );
                  dispatch(
                    addCustomDesignRingValues({
                      ringSettingBudgetMin: "notSure",
                    })
                  );
                  dispatch(
                    addCustomDesignRingValues({
                      ringSettingBudgetMax: "notSure",
                    })
                  );
                } else {
                  dispatch(
                    removeCustomDesignRingValues({
                      centerStoneBudgetMin: "notSure",
                    })
                  );
                  dispatch(
                    removeCustomDesignRingValues({
                      centerStoneBudgetMax: "notSure",
                    })
                  );
                  dispatch(
                    removeCustomDesignRingValues({
                      ringSettingBudgetMin: "notSure",
                    })
                  );
                  dispatch(
                    removeCustomDesignRingValues({
                      ringSettingBudgetMax: "notSure",
                    })
                  );
                }
              }}
            />
            <label htmlFor={`notSure`} className="diamond-mr-20">
              <span>Not Sure</span>
            </label>
          </span>
        </Row>
      </div>
    </>
  );
}
