import React from "react";

const ExportColor = ({color}) => {

    switch(color) {
        case "D":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        case "E":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        case "F":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        case "G":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        case "H":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        case "I":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        case "J":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        case "K":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        case "L":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        case "M":
            return <>
                <p>We recommend selecting diamond color based on what is visible with the naked eye, considering the shape and size of the diamond, as well as the metal color.</p>
                <ul className="diamond-datalist color-details">
                    <li>Most people find diamonds upto I color looking colorless (though this depends on the shape and size as well). F-G-H are best value for money, as visually they look completely colorless.</li>
                    <li>For White metal color setting, select I and above color. For Yellow/Rose metal color setting, the very slight yellowishness of J-K-L colors can get hidden somewhat.</li>
                    <li>Round diamonds generally hide color better than other shapes, while diamond shapes with pointed ends show more color concentration at the points, so better color grade can be selected for these shapes.</li>
                </ul>
                <a href="#">Learn More</a>
            </> 
            
        default:
            return null;
             
    }

}

export default ExportColor;