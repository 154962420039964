import React, { useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { addInComparison, removeInComparison, addWishList, removeWishList } from "../../../redux/actions/diamondAction";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import gridimage from "../../../images/demo-diamond-grid.jpg";
import env from "../../../env.json";
import IndFunctions from "../../common/IndFunctions";
export default function DiamondFinder() {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const { productID } = useParams();
    const navigate = useNavigate();
    function wishlistAdd(diamondID) {
        IndFunctions.wishlistAPI(env.addDiamondWishlist, { diamond_id: diamondID })
        dispatch(addWishList((diamondID)))
    }
    function wishlistRemove(diamondID) {
        IndFunctions.wishlistAPI(env.removeDiamondWishlist, { diamond_id: diamondID })
        dispatch(removeWishList((diamondID)))
    }
    return (<Row className="GridView">
        {state.diamondReducer.diamonds && state.diamondReducer.diamonds.map((diamond, index) => (
            <Col xs={3} key={index} >
                <div className="box compare">
                    <div className="compare-wishlist">
                        <input
                            type="checkbox"
                            name="compare"
                            checked={state.diamondReducer.comparisonDiamond.filter(item => item.diamond_id === diamond.diamond_id).length > 0 ? true : false}
                            onClick={e => e.target.checked === true ? dispatch(addInComparison((diamond))) : dispatch(removeInComparison((diamond)))}
                            disabled={state.diamondReducer.comparisonDiamond.length > 4 && state.diamondReducer.comparisonDiamond.filter(item => item.diamond_id === diamond.diamond_id).length === 0}
                        />
                        <a href="#" className='add-to-wishlist' onClick={e => e.target.checked === true ? wishlistAdd(diamond.diamond_id) : wishlistRemove(diamond.diamond_id)}>
                            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.3651 2.84167C16.9395 2.41584 16.4342 2.07804 15.8779 1.84757C15.3217 1.6171 14.7255 1.49848 14.1235 1.49848C13.5214 1.49848 12.9252 1.6171 12.369 1.84757C11.8128 2.07804 11.3074 2.41584 10.8818 2.84167L9.99847 3.725L9.11514 2.84167C8.25539 1.98193 7.08933 1.49893 5.87347 1.49893C4.65761 1.49893 3.49155 1.98193 2.6318 2.84167C1.77206 3.70141 1.28906 4.86748 1.28906 6.08334C1.28906 7.2992 1.77206 8.46526 2.6318 9.325L3.51514 10.2083L9.99847 16.6917L16.4818 10.2083L17.3651 9.325C17.791 8.89937 18.1288 8.39401 18.3592 7.8378C18.5897 7.28158 18.7083 6.68541 18.7083 6.08334C18.7083 5.48126 18.5897 4.88509 18.3592 4.32887C18.1288 3.77266 17.791 3.2673 17.3651 2.84167V2.84167Z" stroke="#497374" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </a>
                    </div>
                    <div onClick={() => {
                        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
                    }}>
                        <Image src={diamond.has_image_file ? diamond.image_file : env.wp_media_path + gridimage} alt="" className="img-grid-comp" />
                        <div className="data-diamond-grid-details">
                            <h5>{diamond.size && `${diamond.size} Carat`} {diamond.clarity && `| ${diamond.clarity}`}</h5>
                            <p>
                                {diamond.cut && `${diamond.cut} Cut`} {diamond.fluor_intensity && `${diamond.fluor_intensity} Fluorescence`} {diamond.lab && `${diamond.lab} certified`} {diamond.shape && ` | ${diamond.shape} Diamond`}
                            </p>
                            <h6>
                                ${diamond.total_sales_price_in_currency && ` ${diamond.total_sales_price_in_currency}`} {diamond.total_sales_price_in_currency && <strike>$ {diamond.total_sales_price_in_currency}</strike>}
                            </h6>
                        </div>
                    </div>


                </div>
            </Col>
        ))}
    </Row>);
}
