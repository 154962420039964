import { useSelector, useDispatch } from "react-redux";
import ringImg from "../../../src/images/ring-diamond.png";
import imageDatabase from "./imageDatabase.json";
export default function WhoWill() {
  const state = useSelector((state) => state);
  const Images = imageDatabase;
  console.log(state.customDesign);
  const image = () => {
    try {
      if (state.customDesign.metal) {
        if (state.customDesign.centerDiamond == "diamond") {
          let a = {};
          a = Images["diamond"];
          a = a[state.customDesign.diamondShape];
          a = a[state.customDesign.style];
          a = a[state.customDesign.metal];
          return a.image;
        } else {
          let a = {};
          a = Images["shappire"];
          a = a[state.customDesign.shappireShape];
          a = a[state.customDesign.style];
          a = a[state.customDesign.metal];
          return a.image;
        }
      } else if (state.customDesign.style) {
        if (state.customDesign.centerDiamond == "diamond") {
          let a = {};
          a = Images["diamond"];
          a = a[state.customDesign.diamondShape];
          a = a[state.customDesign.style];
          return a.image;
        } else {
          let a = {};
          a = Images["shappire"];
          a = a[state.customDesign.shappireShape];
          a = a[state.customDesign.style];
          return a.image;
        }
      } else if (
        state.customDesign.diamondShape ||
        state.customDesign.shappireShape
      ) {
        if (state.customDesign.diamondShape) {
          let a = {};
          a = Images["diamond"];
          a = a[state.customDesign.diamondShape];
          return a.image;
        } else {
          let a = {};
          a = Images["shappire"];
          a = a[state.customDesign.shappireShape];
          return a.image;
        }
      } else if (state.customDesign.centerDiamond) {
        let a = {};
        a = Images[state.customDesign.centerDiamond];
        return a.image;
      } else {
        return Images.image;
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    // if nothing added then should not go in next screen.
    <div className="img-custom-ring">
      {/* Custom Image */}
      {/* {image()} */}
      <img src={ringImg} width="200" height="200" alt="" />
    </div>
  );
}

/*
 first image - no change
 who will no chnage
 depend on diamond image change - 
 depnd of shape selected image will chnage
 as per style image change
 as per metal image change

 shappire journey
 as per shappire shape and shappire color

*/
