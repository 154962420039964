import React, { useState } from "react";
import { ReactComponent as Envelop } from "../../images/Envelope.svg";
import { ReactComponent as Call } from "../../images/Call.svg";
import { ReactComponent as WhatsApp } from "../../images/contactWhatsapp.svg";

const ContactComponent = () => {

    const [contactFormData, setContactFormData] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);

    const handleUpdate = (e) => {
        setContactFormData({ ...contactFormData, [e.target.id]: e.target.value });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setFormSubmitted(true);
        // console.log(contactFormData);
    }

    var showHtml;

    if (formSubmitted === true) {
        showHtml = <div className="">
            <Envelop />
            <p>Inquiry Submitted. We will contact you shortly.</p>
            <h4>Reach Our Diamond Experts</h4>
            <div className="contacts">
                <a href="tel:+917043557779"><Call /> <span>+91 7043 557779</span></a>
                <a href="https://wa.me/917043557779" target="_blank" rel="noreferrer"><WhatsApp /> <span>+91 7043 557779</span></a>
            </div>
        </div>
    } else {
        showHtml = <>
            <form method="POST" onSubmit={handleSubmit}>
                <div className="form-group">
                    <div className="form-field">
                        <label htmlFor="contactName">Your Name<span className="require">*</span></label>
                        <input type="text" id="contactName" name="contactName" placeholder="Enter Your Name" onChange={handleUpdate} />
                    </div>
                    <div className="form-field">
                        <label htmlFor="contactEmail">Your Email Address<span className="require">*</span></label>
                        <input type="text" id="contactEmail" name="contactEmail" placeholder="Enter Your Email Address" onChange={handleUpdate} />
                    </div>
                </div>
                <div className="form-group">
                    <div className="form-field">
                        <label htmlFor="contactNumber">Contact Number <span>(Optional)</span></label>
                        <input type="text" id="contactNumber" name="contactNumber" placeholder="Enter Your Contact Number" onChange={handleUpdate} />
                    </div>
                    <div className="form-field">
                        <label htmlFor="contactWpNumber">Whatsapp Number <span>(Optional)</span></label>
                        <input type="text" id="contactWpNumber" name="contactWpNumber" placeholder="Enter Your Whatsapp Number" onChange={handleUpdate} />
                    </div>
                </div>
                <div className="form-field">
                    <label htmlFor="contactMessage">Your Message</label>
                    <textarea name="contactMessage" id="contactMessage"
                        placeholder="Send us a message about this diamond..."
                        onChange={handleUpdate}
                    >
                    </textarea>
                </div>
                <input type="submit" value="Contact Diamond Expert" />
            </form>
        </>
    }

    return (
        <>
            {showHtml}
        </>
    );
}

export default ContactComponent;