
import "./diamond-finder.css";
import Filters from "../components/filter/filters";

import EarringListing from '../components/listings/earringsListing'
import axios from "axios";
import env from '../env.json';
import { useSelector, useDispatch } from "react-redux";
import { addDiamondData, addJourneyValue } from "../redux/actions/diamondAction";
import indFunction from '../components/common/IndFunctions';
import { useEffect, useContext } from "react";
import { useLocation } from 'react-router-dom';
import { ApiContext } from '../ApiContext';

export default function EarringFinder({ onSort }) {
  const apiTokenData = useContext(ApiContext);
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();

  function refreshData() {
    if (state.endUserFilter.search) {
      const options = {
        method: 'POST',
        url: env.newRepNetSingleURL,
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': apiTokenData
        },
        data: JSON.stringify({
          request: {
            header: {
              // username: env.username,
              // password: env.password,
            },
            body: {
              diamond_id: parseInt(state.endUserFilter.search)
            },
          }
        }),
      };

      axios(options).then((res) => {

        if (res.data.response.header.error_message !== "" && res.data.response.header.error_message === "No Diamond was found") {
          console.log(res.data.response.header.error_message);
        } else {
          let a = state.endUserFilter

          a.shape = [res.data.response.body.diamond.shape];
          a.fancy_color = [res.data.response.body.diamond.color];
          a.clarity.min = res.data.response.body.diamond.clarity;
          a.clarity.max = res.data.response.body.diamond.clarity;
          a.cut.min = res.data.response.body.diamond.cut;
          a.cut.max = res.data.response.body.diamond.cut;
          a.polish = [res.data.response.body.diamond.polish];
          a.symmetry = [res.data.response.body.diamond.symmetry];
          a.labs = [res.data.response.body.diamond.lab];
          a.fluorescence = [res.data.response.body.diamond.fluor_intensity];

          if (res.data.response.body.diamond.fluor_intensity) a.fluorescence_intensities = res.data.response.body.diamond.fluor_intensity
          const mainFilter = indFunction.changeFilterToRepnet(a, state.diamondReducer.filterMainData, state.diamondReducer.colorTab);
          // console.log('checking earring now', res.data.response.body.diamond, mainFilter,a)
          indFunction.findEarring([res.data.response.body.diamond], mainFilter,1).then(finalEarring => {
            console.log('finished earring now', finalEarring)
            dispatch(addDiamondData(finalEarring, 1))
          })
        }
      })
        .catch(error => {
          console.log("Data fetch error... " + error);
        });
    } else {
      const mainFilter = indFunction.changeFilterToRepnet(state.endUserFilter, state.diamondReducer.filterMainData, state.diamondReducer.colorTab)
      mainFilter.page_size = 60;
      const options = {
        method: 'POST',
        url: env.newRepNetURL,
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded',
          'Authorization': apiTokenData
        },
        data: JSON.stringify({
          request: {
            header: {
              // username: env.username,
              // password: env.password,
            },
            body: mainFilter,
          }
        }),
      };
      axios(options).then((res) => {
        const repRes = res.data.response;
        if (repRes.header.error_message !== "" && repRes.header.error_message === "No Diamond was found") {
          console.log(repRes.header.error_message);
          dispatch(addDiamondData([], 0))
        } else {
          const data = repRes.body;
          if (data.search_results) {
            indFunction.findEarring(data.diamonds, mainFilter).then(finalEarring => {
              dispatch(addDiamondData(finalEarring, data.search_results.total_diamonds_found, data.search_results.diamonds_returned))
            })

          } else {
            console.log('no data coming')
          }
        }
      })
        .catch(error => {
          console.log("Data fetch error... " + error);
        });
    }
  }
  useEffect(() => {
    console.log('token',apiTokenData);
    let loc = location.pathname;
    loc = loc.substring(1, loc.length)
    loc = loc.substring(0, loc.indexOf('/'));
    dispatch(addJourneyValue(loc))
    refreshData()
    console.log('use effect changed')
  }, [state.endUserFilter, state.diamondReducer.filterMainData])

  return (<div className="shop-conflict-free">
    <div className="container">
      <Filters ></Filters>
      <EarringListing />
    </div>
  </div>);
}
