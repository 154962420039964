import {
    ADD_DIAMONDS_DATA,
    FILTER_OPTIONS,
    COLUMNS_MANAGER,
    ADD_IN_COMPARISON,
    REMOVE_IN_COMPARISON,
    LISTING_STYLE, SORTING,
    ADDFILTERS,
    ENDUSERFILTER,
    FIRSTENDUSERFILTER,
    ADDJOURNEY,
    ADD_IN_EARRING_COMPARISON,
    REMOVE_IN_EARRING_COMPARISON,
    COLORTAB,
    CHANGECOMPONENT,
    ADDCUSTOMDESIGNRINGVALUES,
    REMOVECUSTOMDESIGNRINGVALUES,
    RESTARTCUSTOMDESIGN,
    LOADWISHLIST,
    ADDWISHLIST,
    REMOVEWISHLIST,
    LOADEARRINGWISHLIST,
    ADDWISHLISTEARRING,
    REMOVEWISHLISTEARRING
} from "../actionTypes/actionType";

const addDiamondData = (diamondData, totalDiamonds, returnedDiamonds) => {
    return {
        type: ADD_DIAMONDS_DATA,
        payload: {
            totalDiamonds: totalDiamonds,
            returnedDiamonds: returnedDiamonds,
            diamonds: diamondData
        }
    };
};

const filterOptions = () => {
    return {
        type: FILTER_OPTIONS,
        payload: {

        }
    };
};
const columnsManager = (column) => {
    return {
        type: COLUMNS_MANAGER,
        payload: {
            column: column
        },
    };
};
const addInComparison = (diamond) => {
    return {
        type: ADD_IN_COMPARISON,
        payload: {
            diamond: diamond
        }
    };
};
const removeInComparison = (diamond, index) => {
    return {
        type: REMOVE_IN_COMPARISON,
        payload: {
            diamond: diamond,
            index: index
        }
    };
};
const listingStyle = (type) => {
    return {
        type: LISTING_STYLE,
        payload: {
            type: type
        }
    };
};
const sorting = (type) => {
    return {
        type: SORTING,
        payload: type
    };
};
const addMainFilter = (filterData) => {
    return {
        type: ADDFILTERS,
        payload: filterData
    };
};
const endUserFilterAction = (filterData) => {
    return {
        type: ENDUSERFILTER,
        payload: filterData
    };
};
const firstEndUserFilterAction = (filterData) => {
    return {
        type: FIRSTENDUSERFILTER,
        payload: filterData
    };
};
const addJourneyValue = (filterData) => {
    return {
        type: ADDJOURNEY,
        payload: filterData
    };
};
const addComparisionEarring = (filterData) => {
    return {
        type: ADD_IN_EARRING_COMPARISON,
        payload: filterData
    };
};
const removeComparisionEarring = (filterData) => {
    return {
        type: REMOVE_IN_EARRING_COMPARISON,
        payload: filterData
    };
};
const changeColor = (filterData) => {
    return {
        type: COLORTAB,
        payload: filterData
    };
};
const changeComponent = (filterData) => {
    return {
        type: CHANGECOMPONENT,
        payload: filterData
    };
};
const addCustomDesignRingValues = (filterData) => {
    return {
        type: ADDCUSTOMDESIGNRINGVALUES,
        payload: filterData
    };
};
const removeCustomDesignRingValues = (filterData) => {
    return {
        type: REMOVECUSTOMDESIGNRINGVALUES,
        payload: filterData
    };
};
const restartCustomRing = (filterData) => {
    return {
        type: RESTARTCUSTOMDESIGN,
        payload: filterData
    };
};
const loadWishList = (filterData) => {
    return {
        type: LOADWISHLIST,
        payload: filterData
    }
}
const addWishList = (filterData) => {
    return {
        type: ADDWISHLIST,
        payload: filterData
    }
}
const removeWishList = (filterData) => {
    return {
        type: REMOVEWISHLIST,
        payload: filterData
    }
}
const loadWishListEarring = (filterData) => {
    return {
        type: LOADEARRINGWISHLIST,
        payload: filterData
    }
}
const addWishListEarring = (filterData) => {
    return {
        type: ADDWISHLISTEARRING,
        payload: filterData
    }
}
const removeWishListEarring = (filterData) => {
    return {
        type: REMOVEWISHLISTEARRING,
        payload: filterData
    }
}
export {
    addDiamondData,
    filterOptions,
    columnsManager,
    addInComparison,
    removeInComparison,
    listingStyle,
    sorting,
    addMainFilter,
    endUserFilterAction,
    firstEndUserFilterAction,
    addJourneyValue,
    addComparisionEarring,
    removeComparisionEarring,
    changeColor,
    changeComponent,
    addCustomDesignRingValues,
    removeCustomDesignRingValues,
    restartCustomRing,
    loadWishList,
    addWishList,
    removeWishList,
    loadWishListEarring,
    addWishListEarring,
    removeWishListEarring
};