import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { addCustomDesignRingValues, removeCustomDesignRingValues } from "../../redux/actions/diamondAction";
export default function WhoWill() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();

  return (

    <Row>
      <div className="text-center">
      <h1>Centre Stone - Diamond or Sapphire?</h1>
      </div>
      <div className="shape-selct text-center">
        <div className="select--shape">
          <input
            type="radio"
            name="shape"
            id="shape-diamond"
            checked={state.customDesign.centerDiamond == "diamond"}
            className="input-hidden"
            onChange={(e) => {
              if (e.target.checked) {

                dispatch(addCustomDesignRingValues({ centerDiamond: "diamond" }))
              } else {
                dispatch(removeCustomDesignRingValues({ centerDiamond: "diamond" }))

              }
            }}
          />
          <label htmlFor="shape-diamond" className="diamond-mr-20 border-square">
            <Image height="66px" width="66px" src="https://sarvada.wpstagingserver.com/wp-content/uploads/2023/06/Round.png" alt="Diamond" />
            <span>Diamond</span>
          </label>
        </div>
        <div className="select--shape">
          <input
            type="radio"
            name="shape"
            checked={state.customDesign.centerDiamond == "shappire"}
            id="shape-Sapphire"
            className="input-hidden"
            onChange={(e) => {
              if (e.target.checked) {
                dispatch(addCustomDesignRingValues({ centerDiamond: "shappire" }))
              } else {
                dispatch(removeCustomDesignRingValues({ centerDiamond: "shappire" }))

              }
            }}
          />
          <label htmlFor="shape-Sapphire" className="diamond-mr-20 border-square">
            <Image height="66px" width="66px" src="https://sarvada.wpstagingserver.com/wp-content/uploads/2023/06/Round.png" alt="Diamond" />
            <span>Sapphire</span>
          </label>
        </div>
        </div>
        <div>
          <p className="mx-w-p">Next, we will ask a few quick questions regarding the centre stone that will help our team find the perfect stone for you. Yes, that’s correct - our experts do all the hard work and hunt for the perfect one! We don’t sell you the diamond/sapphire but we help you buy the best one.</p>
        </div>
    </Row>

  );
}
