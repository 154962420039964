import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import { addCustomDesignRingValues } from "../../redux/actions/diamondAction";
export default function WhoWill() {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  return (
    <Row>
      <div className="col-md-12">
        <div className="">
          <h1 className="popup-custom-heading">
            Who will be wearing the ring?
          </h1>
        </div>
        <div className="text-center select-who-will">
          <select
            name="cars"
            id="cars"
            value={state.customDesign.whoWill}
            onChange={(e) => {
              dispatch(addCustomDesignRingValues({ whoWill: e.target.value }));
            }}
          >
            <option value="other">My Signifant Other</option>
            <option value="me">me</option>
          </select>
        </div>
      </div>
    </Row>
  );
}
