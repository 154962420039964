import { createStore } from "redux";
import { diamondReducer, endUserFilter, wishlist } from "./reducers/diamondReducer";
import { customDesign } from "./reducers/customDesignRing";

import { combineReducers, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga'

// ...
// import { helloSaga } from './sagas/sagas'
const sagaMiddleware = createSagaMiddleware()

const rootReducer = combineReducers({

    diamondReducer: diamondReducer,
    endUserFilter: endUserFilter,
    wishlist: wishlist,
    customDesign: customDesign
});

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));
// sagaMiddleware.run(helloSaga)
export default store;