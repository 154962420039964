import { NavLink } from "react-router-dom";
import env from '../../../env.json';
import gridimage from "../../../images/demo-diamond-grid.jpg";
import { Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from 'react-router-dom';
import Table from "react-bootstrap/Table";
import { removeComparisionEarring } from "../../../redux/actions/diamondAction";
export default function Compare() {

  const state = useSelector((state) => state);
  const { productID } = useParams();
  console.log(state.diamondReducer.comparisonEarring)
  const dispatch = useDispatch();


  return (
    <>

      <Table
        responsive
        striped
        bordered
        hover
        className="table table-striped table-bordered ext-table text-center compare-table"
      >
        <thead>
          <tr>
            <th>Remove</th>
            <th>Choose Diamond</th>
            <th> Shape </th>
            <th> Carat </th>
            <th> Color  </th>
            <th> Clarity </th>
            <th> Cut </th>
            <th> Polish </th>
            <th> Symmetry </th>
            <th> Fluorescence </th>
            <th> Certificate  </th>
            <th>  Price </th>
            <th> Table % </th>
            <th> Total Depth %  </th>
            <th>  Measurements </th>
          </tr>
        </thead>
        {state.diamondReducer.comparisonEarring && (<tbody>{
          state.diamondReducer.comparisonEarring.map((diamond, index) => (
            <tr key={index} >
              <button onClick={e => { dispatch(removeComparisionEarring(diamond)) }}>Remove</button>
              <td>
                <NavLink
                  to={`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`}
                  className="choose-btn"
                >
                  Choose This Diamond
                </NavLink>
              </td>
              <td> {diamond.one.shape}</td>
              <td> <p>{diamond.one.size}</p><p> {diamond.two ? diamond.two.size : false}</p>  </td>
              <td> <p>{diamond.one.color}</p><p> {diamond.two ? diamond.two.color : false}</p> </td>
              <td> <p>{diamond.one.clarity}</p><p> {diamond.two ? diamond.two.clarity : false}</p>  </td>
              <td>  <p>{diamond.one.cut}</p><p> {diamond.two ? diamond.two.cut : false}</p> </td>
              <td>  <p>{diamond.one.polish}</p><p> {diamond.two ? diamond.two.polish : false}</p>  </td>
              <td>  <p>{diamond.one.symmetry}</p><p> {diamond.two ? diamond.two.symmetry : false}</p>  </td>
              <td>  <p>{diamond.one.fluor_intensity}</p><p> {diamond.two ? diamond.two.fluor_intensity : false}</p>  </td>
              <td>  <p>{diamond.one.lab}</p><p> {diamond.two ? diamond.two.lab : false}</p>  </td>
              <td>  <p>{diamond.one.total_sales_price_in_currency}</p><p> {diamond.two ? diamond.two.total_sales_price_in_currency : false}</p>   </td>
              <td> {diamond.one.table_percent} </td>
              <td> {diamond.one.depth_percent} </td>
              <td> {diamond.one.meas_length}x{diamond.one.meas_width}x{diamond.one.meas_depth} </td>
            </tr>
          ))
        }</tbody>)}

      </Table>

    </>
  );
}
