import {
    CHANGECOMPONENT,
    ADDCUSTOMDESIGNRINGVALUES,
    RESTARTCUSTOMDESIGN,
    REMOVECUSTOMDESIGNRINGVALUES
} from "../actionTypes/actionType";
const initialState = {
    currentComponent: "firstComponent",
    whoWill: "other",
    centerDiamond: "",
    diamondShape: "",
    caratSize: "",
    colorRange: [],
    diamondQuality: "",
    shappireShape: "",
    shappireColor: "",
    shappireCarat: "",
    shappireQuality: "",
    quality: "",
    style: "",
    metal: "",
    metalColor: "",
    shappireSize: "",
    centerStoneBudgetMin: "",
    centerStoneBudgetMax: "",
    ringSettingBudgetMin: "",
    ringSettingBudgetMax: "",
    specialDay: "Yes",
    specialDate: "",
    designInspiration: "",
    designFiles:[],
    inspirationImages: "",
    name: "",
    emailID: "",
    mobileNo: "",
    whatsappNo: "",
    ringSize: "6",
    inpirationURLs:[],
    weddingBand: false

};
export const customDesign = (state = initialState, action) => {
    switch (action.type) {
        case CHANGECOMPONENT:
            return {
                ...state,
                currentComponent: action.payload
            };
        case ADDCUSTOMDESIGNRINGVALUES:
            let abc = {};
            if (action.payload.whoWill) abc.whoWill = action.payload.whoWill;
            if (action.payload.centerDiamond) abc.centerDiamond = action.payload.centerDiamond;
            if (action.payload.diamondShape) abc.diamondShape = action.payload.diamondShape;
            if (action.payload.shappireShape) abc.shappireShape = action.payload.shappireShape;
            if (action.payload.style) abc.style = action.payload.style;
            if (action.payload.metal) abc.metal = action.payload.metal;
            if (action.payload.caratSize) abc.caratSize = action.payload.caratSize;
            if (action.payload.colorRange) abc.colorRange = action.payload.colorRange;
            if (action.payload.diamondQuality) abc.diamondQuality = action.payload.diamondQuality;
            if (action.payload.shappireColor) abc.shappireColor = action.payload.shappireColor;
            if (action.payload.shappireCarat) abc.shappireCarat = action.payload.shappireCarat;
            if (action.payload.shappireQuality) abc.shappireQuality = action.payload.shappireQuality;
            if (action.payload.metalColor) abc.metalColor = action.payload.metalColor;
            if (action.payload.centerStoneBudgetMin) abc.centerStoneBudgetMin = action.payload.centerStoneBudgetMin;
            if (action.payload.centerStoneBudgetMax) abc.centerStoneBudgetMax = action.payload.centerStoneBudgetMax;
            if (action.payload.ringSettingBudgetMin) abc.ringSettingBudgetMin = action.payload.ringSettingBudgetMin;
            if (action.payload.ringSettingBudgetMax) abc.ringSettingBudgetMax = action.payload.ringSettingBudgetMax;
            if (action.payload.specialDay) abc.specialDay = action.payload.specialDay;
            if (action.payload.designInspiration) abc.designInspiration = action.payload.designInspiration;
            if (action.payload.designFiles) abc.designFiles = action.payload.designFiles;
            if (action.payload.name) abc.name = action.payload.name;
            if (action.payload.emailID) abc.emailID = action.payload.emailID;
            if (action.payload.mobileNo) abc.mobileNo = action.payload.mobileNo;
            if (action.payload.whatsappNo) abc.whatsappNo = action.payload.whatsappNo;
            if (action.payload.ringSize) abc.ringSize = action.payload.ringSize;
            if (action.payload.specialDate) abc.specialDate = action.payload.specialDate;
            if (action.payload.inpirationURLs) state.inpirationURLs.push(action.payload.inpirationURLs);
            return {
                ...state,
                ...abc
            };
        case RESTARTCUSTOMDESIGN:
            return {
                ...initialState
            };
        case REMOVECUSTOMDESIGNRINGVALUES:
            let abc1 = {};
            if (action.payload.centerDiamond) abc1.centerDiamond = state.centerDiamond;
            if (action.payload.diamondShape) abc1.diamondShape = state.diamondShape;
            if (action.payload.style) abc1.style = state.style;
            if (action.payload.metal) abc1.metal = state.metal;
            if (action.payload.metalColor) abc1.metalColor = state.metalColor;
            if (action.payload.diamondQuality) abc1.diamondQuality = state.diamondQuality;
            if (action.payload.colorRange) abc1.colorRange = state.colorRange;
            if (action.payload.caratSize) abc1.caratSize = state.caratSize;
            if (action.payload.centerStoneBudgetMin) abc1.centerStoneBudgetMin = state.centerStoneBudgetMin;
            if (action.payload.centerStoneBudgetMax) abc1.centerStoneBudgetMax = state.centerStoneBudgetMax;
            if (action.payload.ringSettingBudgetMin) abc1.ringSettingBudgetMin = state.ringSettingBudgetMin;
            if (action.payload.ringSettingBudgetMax) abc1.ringSettingBudgetMax = state.ringSettingBudgetMax;
            return {
                ...state,
                ...abc1
            }
        default:
            return state;
    }
};