import React from "react";

const ExpertClarity = ({clarity}) => {

    switch (clarity) {
        case "FL": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        case "IF": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        case "VVS1": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        case "VVS2": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        case "VS1": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        case "VS2": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        case "SI1": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        case "SI2": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        case "I1": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        case "I2": 
            return <>
                <p>
                    The important thing to consider is whether the inclusions are
                    visible with naked eyes, without magnification and whether they
                    impact the light performance of the diamond or not. So, we
                    recommend buying an eye-clean diamond and this varies based on the
                    size and shape of the diamond.
                </p>
                <ul className="diamond-datalist color-details">
                    <li>
                        Usually, brilliant cut diamonds like round, princess etc hide
                        the inclusions better than step-cut diamonds like Emerald and
                        Asscher.
                    </li>
                    <li>
                        Also, usually upto SI1 grade, diamonds are eye-clean under 2.00
                        carat size unless it’s a weak SI1. Ask our diamond experts to
                        physically inspect the diamond if you are selecting SI1 or
                        below. It’s not necessary to only buy VVS or higher grade
                        diamonds.
                    </li>
                    <li>
                        Recommendation also depends on size. In 0.40 carat even SI2 will
                        always be eye-clean whereas as the diamond becomes bigger, it’s
                        easier to spot inclusions so SI2 may not be eye-clean in say a
                        2.50 carat size.
                    </li>
                </ul>
                <a href="#">Learn More</a>
            </>
            
        default:
            return null;
            
    }
}

export default ExpertClarity;