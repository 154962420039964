import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import { addCustomDesignRingValues, removeCustomDesignRingValues } from "../../redux/actions/diamondAction";
import indFunction from '../common/IndFunctions';
import { useEffect } from "react";
import env from '../../env.json';
import axios from "axios";
export default function WhoWill() {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const caratSize = [{ index: "0-1", name: "Below 1 Carat" }, { index: "1-1.50", name: "1-1.50 Carat" }, { index: "1.5-1.99", name: "1.5-1.99 Carat" }, { index: "2-2.99", name: "2-2.99 Carat" }, { index: "3-5", name: "3+ Carat" }];
    const colorRange = [{ name: "Blue" }, { name: "Teal" }, { name: "Peach" }, { name: "Pink" }, { name: "Purple" }];
    const diamondQuality = [{ name: "Standard" }, { name: "Delux" }, { name: "Premium" }];
    const caratHTML = caratSize.map((i, index) => (<span key={index}>
        <input
            type="radio"
            name="carat"
            value={i.name}
            checked={state.customDesign.shappireCarat.name == i.name}
            id={`carat-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(addCustomDesignRingValues({ shappireCarat: i }))
                } else {
                    dispatch(removeCustomDesignRingValues({ shappireCarat: i }))
                }
            }}
        />
        <label
            htmlFor={`carat-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract"
        >
            {i.name}
        </label>
    </span>));
    const colorRangeHTML = colorRange.map((i, index) => (<div key={index}>
        <input
            type="radio"
            name="colorRange"
            value={i.name}
            checked={state.customDesign.shappireColor == i.name}
            id={`colorRange-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(addCustomDesignRingValues({ shappireColor: i.name }))
                } else {
                    dispatch(removeCustomDesignRingValues({ shappireColor: i.name }))
                }
            }}
        />
        <label
            htmlFor={`colorRange-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract color-shappire"
        >
            <i class="circle-color color-gold"></i>{i.name}
        </label>
    </div>));
    const diamondQualityHTML = diamondQuality.map((i, index) => (<div key={index}>
        <input
            type="radio"
            name="diamondQuality"
            value={i.name}
            checked={state.customDesign.shappireQuality == i.name}
            id={`diamondQuality-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(addCustomDesignRingValues({ shappireQuality: i.name }))
                } else {
                    dispatch(removeCustomDesignRingValues({ shappireQuality: i.name }))
                }
            }}
        />
        <label
            htmlFor={`diamondQuality-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract"
        >
            {i.name}
        </label>
    </div>));
    useEffect(() => {
        if (state.customDesign.shappireColor == "notSure" || state.customDesign.shappireCarat == "notSure" || state.customDesign.shappireQuality == "notSure") {

        } else {
            const minOption = {
                method: 'POST',
                url: env.newRepNetURL,
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded', 
                    'Authorization': env.RepNetAuthToken 
                },
                data: JSON.stringify({
                    request: {
                        header: {
                            // username: env.username,
                            // password: env.password,
                        },
                        body: indFunction.customDesignShappireFilter(state.customDesign, "Asc"),
                    }
                }),
            };
            axios(minOption).then((res) => {
                const repRes = res.data.response;
                if (repRes.header.error_message !== "" && repRes.header.error_message === "No Diamond was found") {
                    console.log(repRes.header.error_message);
                } else {
                    const dataOne = repRes.body;
                    if (dataOne.search_results) {
                        dispatch(addCustomDesignRingValues({ centerStoneBudgetMin: dataOne.diamonds[0].total_sales_price_in_currency }))

                    } else {
                        console.log('no data coming')
                    }
                }
            })
                .catch(error => {
                    console.log("Data fetch error... " + error);
                });
            const maxOption = {
                method: 'POST',
                url: env.newRepNetURL,
                headers: { 
                    'Content-Type': 'application/x-www-form-urlencoded', 
                    'Authorization': env.RepNetAuthToken 
                },
                data: JSON.stringify({
                    request: {
                        header: {
                            // username: env.username,
                            // password: env.password,
                        },
                        body: indFunction.customDesignShappireFilter(state.customDesign, "Desc"),
                    }

                }),
            };
            axios(maxOption).then((res) => {
                const repRes = res.data.response;
                if (repRes.header.error_message !== "" && repRes.header.error_message === "No Diamond was found") {
                    console.log(repRes.header.error_message);
                } else {
                    const data = repRes.body;
                    if (data.search_results) {
                        dispatch(addCustomDesignRingValues({ centerStoneBudgetMax: data.diamonds[0].total_sales_price_in_currency }))
                    } else {
                        console.log('no data coming')
                    }
                }
            })
                .catch(error => {
                    console.log("Data fetch error... " + error);
                });

        }

    }, [state.customDesign.shappireShape, state.customDesign.shappireCarat, state.customDesign.shappireColor, state.customDesign.shappireQuality])

    return (
        <>
            <Row>
            <div class="text-center"><h1>Centre Shappire</h1></div>

            </Row>
            <Row>
            <Col md={12}> <div className="inner-label">Color</div></Col>
            <Col md={12}><div className="shape-selct-diamond text-center">
            {colorRangeHTML}
                    </div>
                </Col>
                
            </Row>
            <Row>
            <Col md={12}> <div className="inner-label"> CaratSize</div></Col>
            <Col md={12}><div className="shape-selct-diamond text-center">
            {caratHTML}
                    </div>
                </Col>
                
            </Row>
            <Row>
            <Col md={12}> <div className="inner-label"> Quality</div></Col>
            <Col md={12}> <div className="shape-selct-diamond text-center">{diamondQualityHTML}</div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <input
                        type="checkbox"
                        name="notSure"
                        checked={state.customDesign.shappireColor == "notSure"}
                        id={`notSure`}
                        className="input-hiddens"
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(addCustomDesignRingValues({ shappireColor: "notSure" }))
                                dispatch(addCustomDesignRingValues({ shappireCarat: "notSure" }))
                                dispatch(addCustomDesignRingValues({ shappireQuality: "notSure" }))
                            } else {
                                dispatch(removeCustomDesignRingValues({ shappireColor: "notSure" }))
                                dispatch(removeCustomDesignRingValues({ shappireCarat: "notSure" }))
                                dispatch(removeCustomDesignRingValues({ shappireQuality: "notSure" }))

                            }
                        }}
                    />
                    <label
                        htmlFor={`notSure`}
                        className="diamond-mr-20 group-right-0"
                    >
                        Not Sure Yet
                    </label>
                </Col>
            </Row>
            <Row>
                <Col>
                    Minimum Price {state.customDesign.centerStoneBudgetMin}
                </Col>
                <Col>
                    Maximum Price {state.customDesign.centerStoneBudgetMax}
                </Col>
            </Row>
        </>
    );
}
