import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import { Row, Col, Image } from "react-bootstrap";
import gridimage from "../../../images/demo-diamond-grid.jpg";
import { Pagination } from 'antd';
import env from "../../../env.json";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { addInComparison, removeInComparison } from '../../../redux/actions/diamondAction'

export default function Whishlist() {
    const { productID } = useParams();
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [diamondDetails, setDiamondDetails] = useState([])

    function recursiveData(index, diamonds, diamondDetails, cb) {
        const options = {
            method: 'POST',
            url: env.newRepNetSingleURL,
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded', 
                'Authorization': env.RepNetAuthToken 
            },
            data: JSON.stringify({
                request: {
                    header: {
                        // username: env.username,
                        // password: env.password,
                    },
                    body: {
                        diamond_id: parseInt(diamonds[index])
                    },
                }
            }),
        };
        axios(options).then((res) => {

            if (res.data.response.header.error_message !== "" && res.data.response.header.error_message === "No Diamond was found") {
                console.log(res.data.response.header.error_message);
            } else {
                diamondDetails.push(res.data.response.body.diamond)
            }
            index = index + 1
            if (index < diamonds.length) {
                recursiveData(index, diamonds, diamondDetails, cb);
            } else {
                cb(diamondDetails)
            }
        })
            .catch(error => {
                console.log("Data fetch error... " + error);
                index = index + 1
                recursiveData(index, diamonds, diamondDetails, cb);
            });
    }

    useEffect(() => {
        recursiveData(0, state.wishlist.diamonds, [], (d) => {
            setDiamondDetails([...d])
        })
    }, [])
    const diamondsData = diamondDetails ? diamondDetails.map((diamond, index) => (
        <tr key={index} >
            <td>
                <input
                    type="checkbox"
                    name="compare"
                    checked={state.diamondReducer.comparisonDiamond.filter(item => item.diamond_id === diamond.diamond_id).length > 0 ? true : false}
                    onClick={e => e.target.checked === true ? dispatch(addInComparison((diamond))) : dispatch(removeInComparison((diamond)))}
                    disabled={state.diamondReducer.comparisonDiamond.length > 4 && state.diamondReducer.comparisonDiamond.filter(item => item.diamond_id === diamond.diamond_id).length === 0}
                />
            </td>
            <td> {diamond.shape} </td>
            <td> {diamond.size} </td>
            <td> {diamond.color} </td>
            <td> {diamond.clarity} </td>
            <td> {diamond.cut} </td>
            <td> {diamond.polish} </td>
            <td> {diamond.symmetry} </td>
            <td> {diamond.fluor_intensity} </td>
            <td> {diamond.lab} </td>
            <td> {diamond.total_sales_price_in_currency} </td>
            <td> {diamond.table_percent} </td>
            <td> {diamond.depth_percent} </td>
            <td> {diamond.meas_length}x{diamond.meas_width}x{diamond.meas_depth} </td>
        </tr>
    )) : <p>No Whishlist Data Found</p>;

    return (
        <>
            <Row className="ListView">
                <Table
                    responsive
                    striped
                    bordered
                    hover
                    className="ext-table text-center"
                >
                    <thead>
                        <tr>
                            <th> Add To <br /> Compare </th>
                            <th> Shape </th>
                            <th> Carat </th>
                            <th> Color  </th>
                            <th> Clarity </th>
                            <th> Cut </th>
                            <th> Polish </th>
                            <th> Symmetry </th>
                            <th> Fluorescence </th>
                            <th> Certificate  </th>
                            <th> Price </th>
                            <th> Table % </th>
                            <th> Total Depth %  </th>
                            <th>  Measurements </th>
                        </tr>
                    </thead>
                    {diamondDetails && <tbody>{diamondsData}</tbody>}

                </Table>
            </Row>
        </>
    );
}