import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../../pages/custom-design-ring.css";
import { useSelector, useDispatch } from "react-redux";
import { changeComponent } from "../../redux/actions/diamondAction";
export default function WhoWill() {
  const dispatch = useDispatch();
  return (
    <Row>
      <div className="mdle-content">
        <p class="static-text-design">Design your bespiock ring</p>
        <button
          onClick={(e) => dispatch(changeComponent("whoWill"))}
          className="start-btn "
        >
          Let’s create something amazing!
        </button>
      </div>
      <p className="mx-w-p">
                  Design your ring from scratch. Just tell us your preferences
                  and our gemologists and design consultants will work with you
                  to find the perfect diamond/sapphire and create a stunning,
                  bespoke ring design - just for you!
                </p>
    </Row>
  );
}
