import {
    ADD_DIAMONDS_DATA,
    FILTER_OPTIONS,
    COLUMNS_MANAGER,
    ADD_IN_COMPARISON,
    REMOVE_IN_COMPARISON,
    LISTING_STYLE,
    SORTING,
    ADDFILTERS,
    ENDUSERFILTER,
    FIRSTENDUSERFILTER,
    ADDJOURNEY,
    ADD_IN_EARRING_COMPARISON,
    REMOVE_IN_EARRING_COMPARISON,
    COLORTAB,
    LOADWISHLIST,
    ADDWISHLIST,
    REMOVEWISHLIST,
    LOADEARRINGWISHLIST,
    ADDWISHLISTEARRING,
    REMOVEWISHLISTEARRING
} from "../actionTypes/actionType";

const initialState = {
    journey: "",
    diamonds: [],
    totalDiamonds: 0,
    returnedDiamonds: 0,
    filterData: {

    },
    columnsManager: {
        shape: true,
        carat: true,
        color: true,
        clearity: true,
        cut: true,
        polish: true,
        symmetry: true,
        fluorescene: true,
        certificate: true,
        price: true,
        table: false,
        totaldepth: false,
        measurements: false,
        LWRatio: false,
        ShadeMilky: false
    },
    comparisonDiamond: [],
    comparisonEarring: [],
    listingType: "table",
    sorting: "",
    filterMainData: {
        shape: [],
        carat: {},
        fancy_color: [],
        dominantColors: [],
        fancy_diamond_color: [],
        fancy_color_intensity: [],
        colorIntensity: [],
        clarity: {},
        cut: [],
        polish: [],
        symmetry: [],
        fluorescence: [],
        certificate: [],
        price: {},
        table: {},
        total_depth: {},
        ratio: {},
        totaldepth: {},
        measurements: {},
        LWRatio: {},
        shademilky: []
    },
    colorTab: "color"

};
const endUserFilterInitialState = {
    shape: [],
    carat: {},
    searchType: "SKU",
    fancy_color: [],
    dominantColors: [],
    colorIntensity: [],
    fancy_diamond_color: [],
    fancy_color_intensity: [],
    clarity: {},
    cut: [],
    polish: [],
    symmetry: [],
    fluorescence: [],
    certificate: [],
    price: {},
    table: {},
    total_depth: {},
    ratio: {},
    totaldepth: {},
    measurements: {},
    LWRatio: {},
    shademilky: [],
    page_number: 1,
    page_size: 40,
    sort_by: "price",
    sort_direction: "Asc",
    search: "",
    earringSearch: ""

}
const wishListIntialState = {
    diamonds: [],
    earringDiamonds: []
}
export const diamondReducer = (state = initialState, action) => {
    switch (action.type) {
        case ADD_DIAMONDS_DATA:

            return {
                ...state,
                diamonds: action.payload.diamonds,
                totalDiamonds: action.payload.totalDiamonds,
                returnedDiamonds: action.payload.returnedDiamonds,
            };
        case FILTER_OPTIONS:

            return {
                ...state,

            };
        case COLUMNS_MANAGER:
            return {
                ...state,
                columnsManager: {
                    shape: action.payload.column.shape != undefined ? action.payload.column.shape : state.columnsManager.shape,
                    carat: action.payload.column.carat != undefined ? action.payload.column.carat : state.columnsManager.carat,
                    color: action.payload.column.color != undefined ? action.payload.column.color : state.columnsManager.color,
                    clearity: action.payload.column.clearity != undefined ? action.payload.column.clearity : state.columnsManager.clearity,
                    cut: action.payload.column.cut != undefined ? action.payload.column.cut : state.columnsManager.cut,
                    polish: action.payload.column.polish != undefined ? action.payload.column.polish : state.columnsManager.polish,
                    symmetry: action.payload.column.symmetry != undefined ? action.payload.column.symmetry : state.columnsManager.symmetry,
                    fluorescene: action.payload.column.fluorescene != undefined ? action.payload.column.fluorescene : state.columnsManager.fluorescene,
                    certificate: action.payload.column.certificate != undefined ? action.payload.column.certificate : state.columnsManager.certificate,
                    price: action.payload.column.price != undefined ? action.payload.column.price : state.columnsManager.price,
                    table: action.payload.column.table != undefined ? action.payload.column.table : state.columnsManager.table,
                    totaldepth: action.payload.column.totaldepth != undefined ? action.payload.column.totaldepth : state.columnsManager.totaldepth,
                    measurements: action.payload.column.measurements != undefined ? action.payload.column.measurements : state.columnsManager.measurements,
                    LWRatio: action.payload.column.LWRatio != undefined ? action.payload.column.LWRatio : state.columnsManager.LWRatio,
                    shademilky: action.payload.column.shademilky != undefined ? action.payload.column.shademilky : state.columnsManager.shademilky
                }
            };
        case ADD_IN_COMPARISON:
            state.comparisonDiamond.push(action.payload.diamond)
            return {
                ...state,
                comparisonDiamond: state.comparisonDiamond
            };
        case REMOVE_IN_COMPARISON:

            return {
                ...state,
                comparisonDiamond: state.comparisonDiamond.filter(item => item.diamond_id !== action.payload.diamond.diamond_id)
            };
        case ADD_IN_EARRING_COMPARISON:
            let cd = state.comparisonEarring;
            cd.push(action.payload)
            return {
                ...state,
                comparisonEarring: cd
            };
        case REMOVE_IN_EARRING_COMPARISON:
            console.log(action.payload, state.comparisonEarring, '--------')
            return {
                ...state,
                comparisonEarring: state.comparisonEarring.filter(item => item.one.diamond_id !== action.payload.one.diamond_id)
            };

        case LISTING_STYLE:
            return {
                ...state,
                listingType: action.payload
            };
        case ADDFILTERS:
            return {
                ...state,
                filterMainData: {
                    shape: action.payload.shape != undefined ? action.payload.shape : state.filterMainData.shape,
                    carat: action.payload.carat != undefined ? action.payload.carat : state.filterMainData.carat,
                    fancy_color: action.payload.fancy_color != undefined ? action.payload.fancy_color : state.filterMainData.fancy_color,
                    dominantColors: action.payload.dominantColors != undefined ? action.payload.dominantColors : state.filterMainData.dominantColors,
                    fancy_diamond_color: action.payload.fancy_diamond_color != undefined ? action.payload.fancy_diamond_color : state.filterMainData.fancy_diamond_color,
                    fancy_color_intensity: action.payload.fancy_color_intensity != undefined ? action.payload.fancy_color_intensity : state.filterMainData.fancy_color_intensity,
                    colorIntensity: action.payload.colorIntensity != undefined ? action.payload.colorIntensity : state.filterMainData.colorIntensity,
                    clarity: action.payload.clarity != undefined ? action.payload.clarity : state.filterMainData.clarity,
                    cut: action.payload.cut != undefined ? action.payload.cut : state.filterMainData.cut,
                    polish: action.payload.polish != undefined ? action.payload.polish : state.filterMainData.polish,
                    symmetry: action.payload.symmetry != undefined ? action.payload.symmetry : state.filterMainData.symmetry,
                    fluorescence: action.payload.fluorescence != undefined ? action.payload.fluorescence : state.filterMainData.fluorescence,
                    certificate: action.payload.certificate != undefined ? action.payload.certificate : state.filterMainData.certificate,
                    price: action.payload.price != undefined ? action.payload.price : state.filterMainData.price,
                    table: action.payload.table != undefined ? action.payload.table : state.filterMainData.table,
                    totaldepth: action.payload.totaldepth != undefined ? action.payload.totaldepth : state.filterMainData.totaldepth,
                    measurements: action.payload.measurements != undefined ? action.payload.measurements : state.filterMainData.measurements,
                    LWRatio: action.payload.LWRatio != undefined ? action.payload.LWRatio : state.filterMainData.LWRatio,
                    shademilky: action.payload.shademilky != undefined ? action.payload.shademilky : state.filterMainData.shademilky,
                    total_depth: action.payload.total_depth != undefined ? action.payload.total_depth : state.filterMainData.total_depth
                }
            };
        case ADDJOURNEY:

            return {
                ...state,
                journey: action.payload
            }
        case COLORTAB:
            return {
                ...state,
                colorTab: action.payload
            }
        default:
            return state;
    }
};

export const endUserFilter = (state = endUserFilterInitialState, action) => {
    switch (action.type) {
        case FIRSTENDUSERFILTER:
            return {
                ...state,
                shape: action.payload.productFilter.shape,
                carat: action.payload.productFilter.carat,
                fancy_color: action.payload.productFilter.fancy_color,
                dominantColors: action.payload.productFilter.dominantColors,
                colorIntensity: action.payload.productFilter.colorIntensity,
                clarity: {
                    min: action.payload.productFilter.clarity[0],
                    max: action.payload.productFilter.clarity[1],
                },
                cut: {
                    min: action.payload.productFilter.cut[0],
                    max: action.payload.productFilter.cut[1],
                },
                fancy_diamond_color: action.payload.productFilter.fancy_diamond_color,
                fancy_color_intensity: action.payload.productFilter.fancy_color_intensity,
                polish: action.payload.productFilter.polish,
                symmetry: action.payload.productFilter.symmetry,
                fluorescence: action.payload.productFilter.fluorescence,
                price: action.payload.productFilter.price,
                table: action.payload.productFilter.table,
                totaldepth: action.payload.productFilter.totaldepth,
                measurements: action.payload.productFilter.measurements,
                LWRatio: action.payload.productFilter.LWRatio,
                shademilky: action.payload.productFilter.shademilky,
                total_depth: action.payload.productFilter.total_depth,
                certificate: action.payload.productFilter.certificate.map(i => { return { name: i } }),
            }
        case ENDUSERFILTER:
            if (action.payload.shape != undefined) return { ...state, shape: action.payload.shape };
            if (action.payload.carat != undefined) { return { ...state, carat: action.payload.carat } }
            if (action.payload.fancy_color != undefined) { return { ...state, fancy_color: action.payload.fancy_color } }
            if (action.payload.fancy_diamond_color != undefined) { return { ...state, fancy_diamond_color: action.payload.fancy_diamond_color } }
            if (action.payload.fancy_color_intensity != undefined) { return { ...state, fancy_color_intensity: action.payload.fancy_color_intensity } }
            if (action.payload.dominantColors != undefined) { return { ...state, dominantColors: action.payload.dominantColors } }
            if (action.payload.colorIntensity != undefined) { return { ...state, colorIntensity: action.payload.colorIntensity } }
            if (action.payload.clarity != undefined) { return { ...state, clarity: action.payload.clarity } }
            if (action.payload.cut != undefined) { return { ...state, cut: action.payload.cut } }
            if (action.payload.polish != undefined) { return { ...state, polish: action.payload.polish } }
            if (action.payload.symmetry != undefined) { return { ...state, symmetry: action.payload.symmetry } }
            if (action.payload.fluorescence != undefined) { return { ...state, fluorescence: action.payload.fluorescence } }
            if (action.payload.price != undefined) { return { ...state, price: action.payload.price } }
            if (action.payload.table != undefined) { return { ...state, table: action.payload.table } }
            if (action.payload.measurements != undefined) { return { ...state, measurements: action.payload.measurements } }
            if (action.payload.LWRatio != undefined) { return { ...state, LWRatio: action.payload.LWRatio } }
            if (action.payload.shademilky != undefined) { return { ...state, shademilky: action.payload.shademilky } }
            if (action.payload.total_depth != undefined) { return { ...state, total_depth: action.payload.total_depth } }
            if (action.payload.certificate != undefined) { return { ...state, certificate: action.payload.certificate } }
            if (action.payload.page_number != undefined) { return { ...state, page_number: action.payload.page_number } }
            if (action.payload.earringSearch != undefined) { return { ...state, earringSearch: action.payload.earringSearch } }
            if (action.payload.searchType != undefined) { return { ...state, searchType: action.payload.searchType } }
            if (action.payload.search != undefined) { return { ...state, search: action.payload.search } }
            return state;
        case SORTING:
            return {
                ...state,
                sort_by: action.payload
            }
        default:
            return state;
    }
};

export const wishlist = (state = wishListIntialState, action) => {
    switch (action.type) {
        case LOADWISHLIST:
            return {
                ...state,
                diamonds: action.payload
            }
        case ADDWISHLIST:
            const d = state.diamonds;
            d.push(action.payload)
            return {
                ...state,
                diamonds: d
            }
        case REMOVEWISHLIST:
            return {
                ...state,
                diamonds: state.diamonds.filter(item => item !== action.payload)
            }
        case LOADEARRINGWISHLIST:
            return {
                ...state,
                earringDiamonds: action.payload
            }
        case ADDWISHLISTEARRING:
            const de = state.earringDiamonds;
            de.push(action.payload)
            return {
                ...state,
                earringDiamonds: de
            }
        case REMOVEWISHLISTEARRING:
            return {
                ...state,
                earringDiamonds: state.earringDiamonds.filter(item => item.one !== action.payload.one)
            }
        default:
            return state;
    }
}