// Diamond listing feature
export const ADD_DIAMONDS_DATA = "ADD_DIAMONDS_DATA";
export const FILTER_OPTIONS = "FILTER_OPTIONS";
export const COLUMNS_MANAGER = "COLUMNS_MANAGER";
export const ADD_IN_COMPARISON = "ADD_IN_COMPARISON";
export const ADD_IN_EARRING_COMPARISON = "ADD_IN_EARRING_COMPARISON";
export const REMOVE_IN_COMPARISON = "REMOVE_IN_COMPARISON";
export const REMOVE_IN_EARRING_COMPARISON = "REMOVE_IN_EARRING_COMPARISON";
export const LISTING_STYLE = "LISTING_STYLE";
export const SORTING = "SORTING";
export const ADDFILTERS = "ADDFILTERS";
export const ENDUSERFILTER = "ENDUSERFILTER";
export const FIRSTENDUSERFILTER = "FIRSTENDUSERFILTER";
export const ADDJOURNEY = "ADDJOURNEY";
export const COLORTAB = "COLOR";
export const CHANGECOMPONENT = "CHANGECOMPONENT"
export const ADDCUSTOMDESIGNRINGVALUES = "ADDCUSTOMDESIGNRINGVALUES"
export const REMOVECUSTOMDESIGNRINGVALUES = "ADDCUSTOMDESIGNRINGVALUES"
export const RESTARTCUSTOMDESIGN = "RESTARTCUSTOMDESIGN";
export const LOADWISHLIST = "LOADWISHLIST"
export const ADDWISHLIST = "ADDWISHLIST"
export const REMOVEWISHLIST  = "REMOVEWISHLIST"
export const LOADEARRINGWISHLIST = "LOADEARRINGWISHLIST"
export const ADDWISHLISTEARRING = "ADDWISHLISTEARRING"
export const REMOVEWISHLISTEARRING  = "REMOVEWISHLISTEARRING"
