
import "./diamond-finder.css";
import Filters from "../components/filter/filters";

import DiamondListing from '../components/listings/diamondListing'
import axios from "axios";
import env from '../env.json';
import { useSelector, useDispatch } from "react-redux";
import { addDiamondData, changeNewData, addJourneyValue } from "../redux/actions/diamondAction";
import indFunction from '../components/common/IndFunctions'
import { useEffect, useContext } from "react";
import { useSearchParams, useLocation } from 'react-router-dom';
import { ApiContext } from '../ApiContext';

export default function DiamondFinder({ onSort, history }) {
  const apiTokenData = useContext(ApiContext);
  
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  function refreshData() {
    if (state.endUserFilter.search) {
      const options = {
        method: 'POST',
        url: env.newRepNetSingleURL,
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded', 
          'Authorization': apiTokenData 
        },
        data: JSON.stringify({
          request: {
            header: {
              // username: env.username,
              // password: env.password,
            },
            body: {
              diamond_id: parseInt(state.endUserFilter.search)
            },
          }
        }),
      };
  
      axios(options).then((res) => {
  
        if (res.data.response.header.error_message !== "" && res.data.response.header.error_message === "No Diamond was found") {
          console.log(res.data.response.header.error_message);
        } else {
          dispatch(addDiamondData([res.data.response.body.diamond], 1))
        }
      })
        .catch(error => {
          console.log("Data fetch error... " + error);
        });
    } else {
      const options = {
        method: 'POST',
        url: env.newRepNetURL,
        headers: { 
          'Content-Type': 'application/x-www-form-urlencoded', 
          'Authorization': apiTokenData 
        },
        data: JSON.stringify({
          request: {
            header: {
              // username: env.username,
              // password: env.password,
            },
            body: indFunction.changeFilterToRepnet(state.endUserFilter, state.diamondReducer.filterMainData, state.diamondReducer.colorTab),
          }
        }),
      };
      axios(options).then((res) => {
        const repRes = res.data.response;
        if (repRes.header.error_message !== "" && repRes.header.error_message === "No Diamond was found") {
          console.log(repRes.header.error_message);
          dispatch(addDiamondData([], 0))
        } else {
          const data = repRes.body;
          if (data.search_results) {
            dispatch(addDiamondData(data.diamonds, data.search_results.total_diamonds_found, data.search_results.diamonds_returned))
          } else {
            console.log('no data coming')
          }
        }
      })
        .catch(error => {
          console.log("Data fetch error... " + error);
        });
    }

  }
  useEffect(() => {
    let loc = location.pathname;
    loc = loc.substring(1, loc.length)
    loc = loc.substring(0, loc.indexOf('/'));
    dispatch(addJourneyValue(loc))
    refreshData()
  }, [state.endUserFilter, state.diamondReducer.filterMainData])

  return (<div className="shop-conflict-free">
    <div className="container">
      <Filters></Filters>
      <DiamondListing />
    </div>
  </div>);
}
