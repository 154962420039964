import React, { useState } from "react";
import diamond from '../../images/diamond.png';
import { Image } from "react-bootstrap";
import env from "../../env.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const QuizQuality = ({ onStateChange }) => {
    const firstBundle = {
        color: 'F',
        clarity: 'VVS2'
    }

    const secondBundle = {
        color: 'H',
        clarity: 'VS2',
    }

    const thirdBundle = {
        color: 'K',
        clarity: 'SI2'
    }

    const handleChange = (e) => {
        switch (e.target.value) {
            case "bundleOne":
                onStateChange(firstBundle);
                break;
            case "bundleTwo":
                onStateChange(secondBundle);
                break;
            case "bundleThree":
                onStateChange(thirdBundle);
                break;
            default:
                break;
        }
    }

    return (
        <div className="quality-model">
            <ul id="progresssteps">
            <li class="active"></li>
            <li class="active"></li>
            <li class="active"></li>
            <li></li>
	        </ul>
            <div className="select-box-quality">
            <Row className="row-bottom radio-btn">
            <h4 className="quiz-heading">What matters the most to you? We can modify the quality specifications later</h4>
                <Col xs={4}>
                    <input type="radio" name="matters" value="bundleOne" id="firstqualityquiz" onChange={handleChange} className="input-hidden" />
                    <label htmlFor="firstqualityquiz" className="diamond-mr-20 box-radio">
                        <h5 className="heading-radiobox">Super High Quality</h5>
                        <p className="sub-headingbox">Completely Colorless,<br /> Very High Clarity</p>
                        <div className="diamond-qualityimg">
                        <Image height="80px" width="80px" src={env.wp_media_path + diamond} alt="" />
                        </div>
                        <p>Color (i): {firstBundle.color}+ <br /> Clarity (i): {firstBundle.clarity}+</p>
                    </label>
                </Col>

                <Col xs={4}>
                    <input type="radio" name="matters" value="bundleTwo" id="secondqualityquiz" onChange={handleChange} className="input-hidden" />
                    <label htmlFor="secondqualityquiz" className="diamond-mr-20 box-radio">
                        <h5 className="heading-radiobox line-ro">Best Balance of Size and Quality</h5>
                        <p className="sub-headingbox">Colorless and eye-clean to the naked eye</p>
                        <div className="diamond-qualityimg">
                        <Image height="80px" width="80px" src={env.wp_media_path + diamond} alt="" />
                        </div>
                        <p>Color (i): {secondBundle.color}+ <br /> Clarity (i): {secondBundle.clarity}+</p>
                    </label>
                </Col>

                <Col xs={4}>
                    <input type="radio" name="matters" value="bundleThree" id="thirdqualityquiz" onChange={handleChange} className="input-hidden" />
                    <label htmlFor="thirdqualityquiz" className="diamond-mr-20 box-radio">
                        <h5 className="heading-radiobox">Maximize Carat Size</h5>
                        <p className="sub-headingbox">Slightly yellowish with some inclusions that may be visible</p>
                        <div className="diamond-qualityimg">
                        <Image height="80px" width="80px" src={env.wp_media_path + diamond} alt="" />
                        </div>
                        <p>Color (i): {thirdBundle.color}+ <br /> Clarity (i): {thirdBundle.clarity}+</p>
                    </label>
                </Col>
            </Row>
            </div>
        </div>
    );
}

export default QuizQuality;
