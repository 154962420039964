import React, { createContext, useState, useEffect } from 'react';
import env from './env.json';

// Create the context
export const ApiContext = createContext();

// Create the provider component
export const ApiProvider = ({ children }) => {
//   const $tokenUrl = env.RepNetAuthTokenUrl;
  const [apiData, setApiData] = useState(null);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await fetch(env.RepNetAuthTokenUrl); // Replace with your API URL
        const res = await response.json();
        console.log(res.access_token);
        setApiData(res.access_token);
      } catch (error) {
        console.error('Error fetching repnet token data from API:', error);
      }
    };

    fetchData();
  }, []);

  return (
    <ApiContext.Provider value={apiData}>
      {children}
    </ApiContext.Provider>
  );
};
