import React, { useState } from "react";
import Table from "react-bootstrap/Table";
import { Row, Col, Image } from "react-bootstrap";
import gridimage from "../../../images/demo-diamond-grid.jpg";
import { Pagination } from 'antd';
import env from "../../../env.json";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import GridTableListing from '../grid/tableGridListing'
import IndFunctions from "../../common/IndFunctions";
import { addInComparison, removeInComparison, addWishList, removeWishList } from "../../../redux/actions/diamondAction";
import { endUserFilterAction } from "../../../redux/actions/diamondAction";
import { useParams } from 'react-router-dom';
import axios from "axios";
// import unlike from "../../../images/un-like.svg";


export default function Tables({
  tableStyle
}) {
  const { productID } = useParams();
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handlePageChange = (page) => {
    console.log(page, 'page')
    dispatch(endUserFilterAction({ page_number: page }))
  }
  function wishlistAdd(diamondID) {
    IndFunctions.wishlistAPI(env.addDiamondWishlist, { diamond_id: diamondID })

    dispatch(addWishList((diamondID)))
  }
  function wishlistRemove(diamondID) {
    IndFunctions.wishlistAPI(env.removeDiamondWishlist, { diamond_id: diamondID })
    dispatch(removeWishList((diamondID)))
  }
  const diamondsData = state.diamondReducer.diamonds ? state.diamondReducer.diamonds.map((diamond, index) => (
    <tr key={index} >
      <td>
        <input
          type="checkbox"
          name="compare"
          checked={state.diamondReducer.comparisonDiamond.filter(item => item.diamond_id === diamond.diamond_id).length > 0 ? true : false}
          onClick={e => e.target.checked === true ? dispatch(addInComparison((diamond))) : dispatch(removeInComparison((diamond)))}
          disabled={state.diamondReducer.comparisonDiamond.length > 4 && state.diamondReducer.comparisonDiamond.filter(item => item.diamond_id === diamond.diamond_id).length === 0}
        />
      </td>
      <td>
        <label className="heart-click">
          <input
            type="checkbox"
            name="Wishlist"
            checked={state.wishlist.diamonds.filter(item => item === diamond.diamond_id).length > 0 ? true : false}
            onClick={e => e.target.checked === true ? wishlistAdd(diamond.diamond_id) : wishlistRemove(diamond.diamond_id)}
            disabled={state.wishlist.diamonds.length > 4 && state.wishlist.diamonds.filter(item => item === diamond.diamond_id).length === 0}
          />
          <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.3651 2.84167C16.9395 2.41584 16.4342 2.07804 15.8779 1.84757C15.3217 1.6171 14.7255 1.49848 14.1235 1.49848C13.5214 1.49848 12.9252 1.6171 12.369 1.84757C11.8128 2.07804 11.3074 2.41584 10.8818 2.84167L9.99847 3.725L9.11514 2.84167C8.25539 1.98193 7.08933 1.49893 5.87347 1.49893C4.65761 1.49893 3.49155 1.98193 2.6318 2.84167C1.77206 3.70141 1.28906 4.86748 1.28906 6.08334C1.28906 7.2992 1.77206 8.46526 2.6318 9.325L3.51514 10.2083L9.99847 16.6917L16.4818 10.2083L17.3651 9.325C17.791 8.89937 18.1288 8.39401 18.3592 7.8378C18.5897 7.28158 18.7083 6.68541 18.7083 6.08334C18.7083 5.48126 18.5897 4.88509 18.3592 4.32887C18.1288 3.77266 17.791 3.2673 17.3651 2.84167V2.84167Z" stroke="#497374" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
        </label>
      </td>
      {state.diamondReducer.columnsManager.shape ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.shape} </td> : false}
      {state.diamondReducer.columnsManager.carat ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.size} </td> : false}
      {state.diamondReducer.columnsManager.color ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.color} </td> : false}

      {state.diamondReducer.columnsManager.clearity ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.clarity} </td> : false}
      {state.diamondReducer.columnsManager.fancy_color_intensity ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.fancy_color_intensity} </td> : false}
      {state.diamondReducer.columnsManager.cut ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.cut} </td> : false}
      {state.diamondReducer.columnsManager.polish ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.polish} </td> : false}
      {state.diamondReducer.columnsManager.symmetry ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.symmetry} </td> : false}
      {state.diamondReducer.columnsManager.fluorescene ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.fluor_intensity} </td> : false}
      {state.diamondReducer.columnsManager.certificate ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.lab} </td> : false}
      {state.diamondReducer.columnsManager.price ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> <p>Rs {diamond.total_sales_price_in_currency}<br /><del>Rs {(diamond.total_sales_price_in_currency + (diamond.total_sales_price_in_currency * env.priceMultiplier)).toFixed()}</del></p> </td> : false}
      {state.diamondReducer.columnsManager.table ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.table_percent} </td> : false}
      {state.diamondReducer.columnsManager.totaldepth ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.depth_percent} </td> : false}
      {state.diamondReducer.columnsManager.measurements ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> {diamond.meas_length}x{diamond.meas_width}x{diamond.meas_depth} </td> : false}
      {/* {state.diamondReducer.columnsManager.LWRatio ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> </td> : false}
      {state.diamondReducer.columnsManager.ShadeMilky ? <td onClick={() => {
        navigate(`/diamond-show/${diamond.diamond_id}/${productID}`)
      }}> </td> : false} */}

    </tr>
  )) : <p>No Diamond Data Found</p>;
  let listingHTML;
  // console.log("this is tablesytel", tableStyle);
  if (tableStyle === "table") {
    listingHTML = (
      <>
        {" "}
        <Row className="ListView">
          <Table
            responsive
            striped
            bordered
            hover
            className="ext-table text-center"
          >
            <thead>
              <tr>
                <th> Add To <br /> Compare </th>
                <th> Wishlist </th>
                {state.diamondReducer.columnsManager.shape ? <th> Shape </th> : false}
                {state.diamondReducer.columnsManager.carat ? <th> Carat </th> : false}
                {state.diamondReducer.columnsManager.color ? <th> Color  </th> : false}
                {state.diamondReducer.columnsManager.clearity ? <th> Clarity </th> : false}
                {state.diamondReducer.columnsManager.fancy_color_intensity ? <th> Intensity </th> : false}
                {state.diamondReducer.columnsManager.cut ? <th> Cut </th> : false}
                {state.diamondReducer.columnsManager.polish ? <th> Polish </th> : false}
                {state.diamondReducer.columnsManager.symmetry ? <th> Symmetry </th> : false}
                {state.diamondReducer.columnsManager.fluorescene ? <th> Fluorescence </th> : false}
                {state.diamondReducer.columnsManager.certificate ? <th> Certificate  </th> : false}
                {state.diamondReducer.columnsManager.price ? <th>  Price </th> : false}
                {state.diamondReducer.columnsManager.table ? <th> Table % </th> : false}
                {state.diamondReducer.columnsManager.totaldepth ? <th> Total Depth %  </th> : false}
                {state.diamondReducer.columnsManager.measurements ? <th>  Measurements </th> : false}
                {/* {state.diamondReducer.columnsManager.LWRatio ? <th> L/W Ratio  </th> : false}
                {state.diamondReducer.columnsManager.ShadeMilky ? <th>  Shade/Milky </th> : false} */}
              </tr>
            </thead>
            {state.diamondReducer.diamonds && <tbody>{diamondsData}</tbody>}

          </Table>
        </Row>
        {/* pagination */}
        <Pagination
          showSizeChanger={false}
          total={state.diamondReducer.totalDiamonds}
          pageSize={state.endUserFilter.page_size}
          onChange={page => { dispatch(endUserFilterAction({ page_number: page })) }}
        />
      </>
    );
  } else {
    listingHTML = (
      <>
        <GridTableListing></GridTableListing>

        {/* pagination */}
        <Pagination
          showSizeChanger={false}
          total={state.diamondReducer.diamonds.totalDiamonds}
          pageSize={state.endUserFilter.page_size}
          onChange={page => { dispatch(endUserFilterAction({ page_number: page })) }}
        />
      </>
    );
  }
  return listingHTML;
}