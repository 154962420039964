import {useState, useEffect} from "react";
import env from "../../env.json";
import axios from "axios";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import marks from '../common/IndFunctions'
import alldiamondshap from "../../images/all-diamond-shape.svg";
import {Slider, Input, Space, Tabs} from "antd";
import Quiz from "../quizComp";
import loaderImgUrl from "../../images/loading.gif";
import {Image} from "react-bootstrap";
import SocialComponent from "../common/SocialComponent";
import {useSelector, useDispatch} from "react-redux";
import {
    addMainFilter, endUserFilterAction, firstEndUserFilterAction, changeColor, addDiamondData
} from "../../redux/actions/diamondAction";
import IndFunctions from "../common/IndFunctions";
import {Button, Modal} from 'antd';
import {useParams, useSearchParams} from 'react-router-dom';

export default function Filters() {
    const state = useSelector((state) => state);
    const {productID} = useParams();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);
    const [isActive, setIsActive] = useState(false);
    const filterMainData = state.diamondReducer.filterMainData;
    const endUserFilter = state.endUserFilter;
    const [searchParams, setSearchParams] = useSearchParams();
    const [modal1Open, setModal1Open] = useState(false);

    const {Search} = Input;

    const handleReset = (e) => {
        e.preventDefault();
        let myval = [];
        window.location.reload();
    };

    useEffect(() => {
        if (state.diamondReducer.filterMainData.shape.length == 0) {
            const shape = searchParams.get('shape')
            if (shape) {
                let temp = endUserFilter.shape;
                temp.push(shape);
                dispatch(endUserFilterAction({shape: temp}))
            }
            const fancyColor = searchParams.get('fancy-color')
            if (fancyColor) {
                dispatch(changeColor("fancy"))
            }
            axios
                .get(env.wpLiveApiURL, {
                    "Access-Control-Allow-Origin": "*", "Content-Type": "application/json",
                })
                .then((res) => {
                    dispatch(addMainFilter(marks.changeFilterDataToRight(res.data)))
                    setIsLoading(false);
                })
                .catch((error) => {
                    alert(error, "wpjson error");
                });
            if (productID != "independentDiamond" && productID != "noRingProduct" && productID != "noPendantProduct" && productID != "noEarringProduct") {
                axios
                    .get(env.wpLiveProductFilter + productID, {
                        "Access-Control-Allow-Origin": "*", "Content-Type": "application/json",
                    })
                    .then((res) => {
                        const endFilter = res.data;

                        dispatch(firstEndUserFilterAction({productFilter: endFilter}))
                    })
                    .catch((error) => {
                        alert(error, "wpjson error");
                    });
            }
        } else {

            setIsLoading(false);
        }


    }, []);

    function functionGetValue(APIMin, APIMax, sliderValue) {
        return (sliderValue / 100) * (APIMax - APIMin) + APIMin;
    }


    const fancy_color = {};
    for (let i = 0; i < filterMainData.fancy_color.length; i++) {
        if (fancy_color[filterMainData.fancy_color[i].color_category]) {
            fancy_color[filterMainData.fancy_color[i].color_category].push(filterMainData.fancy_color[i].Name)
        } else {
            fancy_color[filterMainData.fancy_color[i].color_category] = [];
            fancy_color[filterMainData.fancy_color[i].color_category].push(filterMainData.fancy_color[i].Name)
        }
    }

    let fancy_color_HTML = [];
    for (var prop in fancy_color) {
        if (Object.prototype.hasOwnProperty.call(fancy_color, prop)) {
            fancy_color_HTML.push((<>
                    <div className="color-box-squence">
                        <div class="squence-alphabate">
                            {fancy_color[prop].map((item, index) => (<>
                                    {endUserFilter.fancy_color && <span key={index}>
                  <input
                      type="checkbox"
                      name="diamond-color"
                      checked={endUserFilter.fancy_color.indexOf(item) != -1 ? true : false}
                      id={`diamond-color-${item}`}
                      className="input-hidden"
                      onChange={(e) => {
                          dispatch(endUserFilterAction({fancy_color: IndFunctions.buttonGroupSlider(filterMainData.fancy_color.map(i => i.Name), endUserFilter.fancy_color, item)}))

                      }}
                  />
                    <label
                        htmlFor={`diamond-color-${item}`}
                        className="diamond-mr-20 group-right-0"
                    >
                      {item}
                    </label>
                  </span>}
                                </>))}
                        </div>
                        <div className="text-center top-space">
                            {prop}
                        </div>
                    </div>
                </>))
        }
    }

    return !isLoading ? (<>
            <div className="desktop-data-on">
                <Row className="row-bottom">
                    <Col xs={12} md={6}>
                        <div className="b-the-best">
                            Natural, Conflict-free Diamonds. Incredible Prices.
                            <br/>
                            Unbiased Advice From Experts. Buy The Best From The Best.
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="top-search-bar">
                            <Quiz/>
                            <Space direction="vertical">
                                <div className="search-box-top">
                                    <div className="cert-box-search">
                                        <select onChange={e => {
                                            dispatch(endUserFilterAction({searchType: e.target.value}))
                                        }}>
                                            <option value="SKU">
                                                SKU
                                            </option>
                                            <option value="Certificate">
                                                Certificate Number
                                            </option>
                                        </select>
                                    </div>
                                    <div className="searchbar-cert">
                                        <Search
                                            placeholder="Certificate / SKU Search"
                                            onSearch={(value) => {
                                                console.log(value, 'value')
                                                dispatch(endUserFilterAction({search: value}))
                                            }}
                                        />
                                    </div>

                                </div>

                            </Space>
                            <SocialComponent/>
                        </div>
                    </Col>
                </Row>
                <Row className="row-bottom">
                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="cut-tooltip">
                                Shape
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="image-all-shape">
                                            <img
                                                src={env.wp_media_path + alldiamondshap}
                                                className="full-image-all-shap"
                                                alt="grid-img"
                                            />
                                        </div>
                                        <div className="tooltip-shape-desc">
                                            Since all diamond shapes are very different, unique
                                            characteristics determine quality for each shape. A diamond
                                            of any shape can be selected based on your preferences and
                                            the design you are interested in.
                                            <br/>
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-shapes">
                                                    Read more about diamond shapes
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <div className="diamond-shape-filter-shop">
                            {/* <div className="diamond-mr-20"></div> */}
                            {filterMainData.shape ? filterMainData.shape.length > 0 && filterMainData.shape.map((item) => (
                                <div key={item.name} className="diamond-mr-20 shape-fillter-select">
                                    <input
                                        type="checkbox"
                                        name="shape"
                                        checked={endUserFilter.shape.indexOf(item.name) != -1 ? true : false}
                                        id={`shape-${item.name}`}
                                        value={item.name}
                                        className="input-hidden"
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                let temp = endUserFilter.shape;
                                                temp.push(item.name);
                                                dispatch(endUserFilterAction({shape: temp}))
                                            } else {
                                                let temp = endUserFilter.shape;
                                                for (var i = 0; i < temp.length; i++) {
                                                    if (temp[i] === item.name) {
                                                        temp.splice(i, 1);
                                                    }
                                                    dispatch(endUserFilterAction({shape: temp}))
                                                }
                                            }
                                        }}
                                    />
                                    <label htmlFor={`shape-${item.name}`} className="br-10 pdd-5">
                                        <Image height="28px" width="28px" src={item.image} alt={item.name}/>
                                        {/* <span>{item.name}</span> */}
                                    </label>
                                    <span>{item.name}</span>
                                </div>)) : false}
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="cut-tooltip">Carat
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Carat
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <div className="carat-filter-shop">
                            {filterMainData.carat && (<>
                                    <Slider
                                        range={true}
                                        step={0.01}
                                        value={[parseFloat(100 / ((parseFloat(filterMainData.carat.max) - parseFloat(filterMainData.carat.min)) / parseFloat(endUserFilter.carat.max))), parseFloat(100 / ((parseFloat(filterMainData.carat.max) - parseFloat(filterMainData.carat.min)) / parseFloat(endUserFilter.carat.min)))]}

                                        tooltipVisible={false}
                                        onChange={(value) => {
                                            const Fmax = parseFloat(filterMainData.carat.max),
                                                Fmin = parseFloat(filterMainData.carat.min), V1 = parseFloat(value[0]),
                                                V2 = parseFloat(value[1]);


                                            dispatch(endUserFilterAction({
                                                carat: {
                                                    min: ((V1 / 100) * (Fmax - Fmin) + Fmin).toFixed(2),
                                                    max: ((V2 / 100) * (Fmax - Fmin) + Fmin).toFixed(2),
                                                }
                                            }))
                                        }}
                                    />
                                    <div className="inp-carat">
                                        <input
                                            type="number"
                                            name="min-price"
                                            id="min-price"
                                            className="input-hidden"
                                            value={endUserFilter.carat.min ? endUserFilter.carat.min : filterMainData.carat.min}
                                            onChange={e => {
                                                dispatch(endUserFilterAction({
                                                    carat: {
                                                        min: e.target.value, max: endUserFilter.carat.max,
                                                    }
                                                }))
                                            }}
                                        />
                                        <input
                                            type="number"
                                            name="max-price"
                                            id="max-price"
                                            className="input-hidden"
                                            value={endUserFilter.carat.max ? endUserFilter.carat.max : filterMainData.carat.max}
                                            onChange={e => {
                                                dispatch(endUserFilterAction({
                                                    carat: {
                                                        min: endUserFilter.carat.min, max: e.target.value,
                                                    }
                                                }))
                                            }}
                                        />
                                    </div>
                                </>)}
                        </div>
                    </Col>
                </Row>
                {state.diamondReducer.colorTab == "color" && (<Row className="row-bottom">
                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="Shape-tooltip">
                                <div className="color-btn">Color / <button onClick={e => {
                                    dispatch(changeColor("fancy"))
                                }} className="brn-none">Fancy Colored Diamonds</button></div>
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Color
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Row>
                            <div className="diamond-color-filter-shop">
                                {fancy_color && fancy_color_HTML}
                            </div>
                        </Row>
                    </Col>

                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="cut-tooltip">Clarity
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Clarity
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Slider
                            range={true}
                            marks={marks.clarity(filterMainData.clarity)}
                            step={null}
                            value={[(parseInt(100 / (filterMainData.clarity.length - 1))) * filterMainData.clarity.map(i => i.name).indexOf(endUserFilter.clarity.max), (parseInt(100 / (filterMainData.clarity.length - 1))) * filterMainData.clarity.map(i => i.name).indexOf(endUserFilter.clarity.min)]}
                            tooltipVisible={false}
                            onChange={(value) => {
                                console.log(parseInt(value[0] / (parseInt(100 / (filterMainData.clarity.length - 1)))))
                                dispatch(endUserFilterAction({
                                    clarity: {
                                        min: filterMainData.clarity[parseInt(value[0] / (parseInt(100 / (filterMainData.clarity.length - 1))))].name,
                                        max: filterMainData.clarity[parseInt(value[1] / (parseInt(100 / (filterMainData.clarity.length - 1))))].name,
                                    }
                                }))


                            }}
                        />

                    </Col>
                </Row>)}
                {state.diamondReducer.colorTab == "fancy" && (<Row className="row-bottom">
                    <Col xs={12}>
                        <Col xs={12}>
                            <div className="Shape-tooltip">
                                <button onClick={e => {
                                    dispatch(changeColor("color"))
                                }}>Color
                                </button>
                                / Fancy Colored Diamonds
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Color
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="certificate-color-filter-shop intentgrid">
                                {filterMainData.fancy_diamond_color.length > 0 && filterMainData.fancy_diamond_color.map((item, index) => (
                                    <div key={index}>
                                        <input
                                            type="checkbox"
                                            name={`fancy_diamond_color`}
                                            checked={endUserFilter.fancy_diamond_color.map(i => i.Name).indexOf(item.Name) != -1 ? true : false}
                                            id={`fancy_diamond_color-${item.Name}`}
                                            className="input-hidden"
                                            onChange={(e) => {
                                                console.log(e.target.checked, item.Name)
                                                if (e.target.checked) {
                                                    let temp = endUserFilter.fancy_diamond_color;
                                                    temp.push(item.Name);
                                                    dispatch(endUserFilterAction({fancy_diamond_color: temp}))
                                                } else {
                                                    const temp = endUserFilter.fancy_diamond_color;
                                                    for (var i = 0; i < temp.length; i++) {
                                                        if (temp[i] === item.Name) {
                                                            temp.splice(i, 1);
                                                        }
                                                        dispatch(endUserFilterAction({fancy_diamond_color: temp}))
                                                    }
                                                }
                                            }}
                                        />
                                        <label
                                            htmlFor={`fancy_diamond_color-${item.Name}`}
                                            className="diamond-mr-22 group-right-0">
                                            {item.Name}
                                        </label>
                                    </div>

                                ))}

                            </div>
                        </Col>
                    </Col>
                </Row>)}
                {state.diamondReducer.colorTab == "fancy" && (<Row className="row-bottom">
                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="Shape-tooltip">Intensity
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Color
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <div className="certificate-color-filter-shop intentgrid">
                            {filterMainData.fancy_color_intensity.length > 0 && filterMainData.fancy_color_intensity.map((item, index) => (
                                <span key={index}>
                  <input
                      type="checkbox"
                      name="fancy_color_intensity"
                      checked={endUserFilter.fancy_color_intensity.indexOf(item.Name) != -1 ? true : false}
                      id={`fancy_color_intensity-${item.Name}`}
                      className="input-hidden"
                      onChange={(e) => {

                          dispatch(endUserFilterAction({fancy_color_intensity: IndFunctions.buttonGroupSlider(filterMainData.fancy_color_intensity.map(i => i.Name), endUserFilter.fancy_color_intensity, item.Name)}))


                      }}
                  />
                  <label
                      htmlFor={`fancy_color_intensity-${item.Name}`}
                      className="diamond-mr-22 group-right-0"
                  >
                    {item.Name}
                  </label>
                </span>))}
                        </div>
                    </Col>

                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="cut-tooltip">Clarity
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Clarity
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Slider
                            range={true}
                            marks={marks.clarity(filterMainData.clarity)}
                            step={null}
                            value={[(parseInt(100 / (filterMainData.clarity.length - 1))) * filterMainData.clarity.map(i => i.name).indexOf(endUserFilter.clarity.max), (parseInt(100 / (filterMainData.clarity.length - 1))) * filterMainData.clarity.map(i => i.name).indexOf(endUserFilter.clarity.min)]}
                            tooltipVisible={false}
                            onChange={(value) => {
                                console.log(parseInt(value[0] / (parseInt(100 / (filterMainData.clarity.length - 1)))))
                                dispatch(endUserFilterAction({
                                    clarity: {
                                        min: filterMainData.clarity[parseInt(value[0] / (parseInt(100 / (filterMainData.clarity.length - 1))))].name,
                                        max: filterMainData.clarity[parseInt(value[1] / (parseInt(100 / (filterMainData.clarity.length - 1))))].name,
                                    }
                                }))


                            }}
                        />

                    </Col>
                </Row>)}
                <Row className="row-bottom">
                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="cut-tooltip">Cut
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Cut
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Slider
                            range={true}
                            marks={marks.cut(filterMainData.cut)}
                            step={null}
                            value={[(parseInt(100 / (filterMainData.cut.length - 1))) * filterMainData.cut.map(i => i.name).indexOf(endUserFilter.cut.max), (parseInt(100 / (filterMainData.cut.length - 1))) * filterMainData.cut.map(i => i.name).indexOf(endUserFilter.cut.min)]}
                            tooltipVisible={false}
                            onChange={(value) => {

                                dispatch(endUserFilterAction({
                                    cut: {
                                        min: filterMainData.cut[parseInt(value[0] / (parseInt(100 / (filterMainData.cut.length - 1))))].name,
                                        max: filterMainData.cut[parseInt(value[1] / (parseInt(100 / (filterMainData.cut.length - 1))))].name,
                                    }
                                }))


                            }}
                        />

                    </Col>
                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="Shape-tooltip">Fluorescence
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Fluorescence
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="Fluorescence-color-filter-shop fluorescence-flex">
                                {filterMainData.fluorescence.length > 0 && filterMainData.fluorescence.map((item, index) => (
                                    <div key={index} className="box-fluoresecence">
                                        <input
                                            type="checkbox"
                                            name={`fluorescence`}
                                            checked={endUserFilter.fluorescence.indexOf(item.name) != -1 ? true : false}
                                            id={`fluorescence-${item.name}`}
                                            className="input-hidden"
                                            onChange={(e) => {

                                                dispatch(endUserFilterAction({fluorescence: IndFunctions.buttonGroupSlider(filterMainData.fluorescence.map(i => i.name), endUserFilter.fluorescence, item.name)}))

                                            }}
                                        />
                                        <label
                                            htmlFor={`fluorescence-${item.name}`}
                                            className="diamond-mr-20 group-right-0"
                                        >
                                            {item.name}
                                        </label>

                                    </div>))}
                            </div>
                        </Col>
                    </Col>
                </Row>
                <Row className="row-bottom">
                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="Shape-tooltip">Certificate
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Certificate
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <div className="certificate-color-filter-shop">
                            {filterMainData.certificate.length > 0 && filterMainData.certificate.map((item, index) => (
                                <span key={index}>
                  <input
                      type="checkbox"
                      name="certificate"
                      value={item.name}
                      checked={endUserFilter.certificate.map(i => i.name).indexOf(item.name) != -1 ? true : false}
                      id={`certificate-${item.name}`}
                      className="input-hidden"
                      onChange={(e) => {

                          if (e.target.checked) {
                              let temp = endUserFilter.certificate;
                              temp.push(item);
                              dispatch(endUserFilterAction({certificate: temp}))
                          } else {
                              const temp = endUserFilter.certificate;
                              for (var i = 0; i < temp.length; i++) {
                                  if (temp[i].name === item.name) {
                                      temp.splice(i, 1);
                                  }
                                  dispatch(endUserFilterAction({certificate: temp}))

                              }
                          }


                      }}
                  />
                  <label
                      htmlFor={`certificate-${item.name}`}
                      className="diamond-mr-20 group-right-0 certificate-lable"
                  >
                    {item.name}
                  </label>
                </span>))}
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <Col xs={12}>
                            <div className="cut-tooltip">Price
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Price
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        {filterMainData.price && endUserFilter.price && (<>
                                <Slider
                                    range={true}
                                    step={10}
                                    tooltipVisible={false}
                                    value={[parseInt(100 / ((parseInt(filterMainData.price.max) - parseInt(filterMainData.price.min)) / parseInt(endUserFilter.price.max))), parseInt(100 / ((parseInt(filterMainData.price.max) - parseInt(filterMainData.price.min)) / parseInt(endUserFilter.price.min)))

                                    ]}
                                    onChange={(value) => {

                                        dispatch(endUserFilterAction({
                                            price: {
                                                min: functionGetValue(parseInt(filterMainData.price.min), parseInt(filterMainData.price.max), value[0]),
                                                max: functionGetValue(parseInt(filterMainData.price.min), parseInt(filterMainData.price.max), value[1]),
                                            }
                                        }))

                                    }}
                                />
                                <div className="price-inpt amt-price-carat">
                                    <input
                                        type="number"
                                        name="min-price"
                                        id="min-price"
                                        className="input-hidden"
                                        value={endUserFilter.price.min ? endUserFilter.price.min : filterMainData.price.min}
                                        readOnly
                                    />
                                    <input
                                        type="number"
                                        name="max-price"
                                        id="max-price"
                                        className="input-hidden"
                                        value={endUserFilter.price.max ? endUserFilter.price.max : filterMainData.price.max}
                                        readOnly
                                    />
                                </div>
                            </>)}
                    </Col>
                </Row>
                <div
                    className="more-filter-rows"
                    style={{display: isActive ? "" : "none"}}
                >
                    <Row className="row-bottom">
                        <Col xs={12} md={6}>
                            <Col xs={12}>
                                <div className="cut-tooltip">Polish
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Polish
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <div className="polish-filter-shop">
                                {filterMainData.polish.length > 0 && filterMainData.polish.map((item, index) => (
                                    <span key={index}>
                    <input
                        type="checkbox"
                        name="polish"
                        id={`polish-${item.name}`}
                        className="input-hidden"
                        checked={endUserFilter.polish.indexOf(item.name) != -1 ? true : false}
                        onChange={(e) => {
                            dispatch(endUserFilterAction({polish: IndFunctions.buttonGroupSlider(filterMainData.polish.map(i => i.name), endUserFilter.polish, item.name)}))


                        }}
                    />
                    <label
                        htmlFor={`polish-${item.name}`}
                        className="diamond-mr-20 group-right-0"
                    >
                      {" "}
                        {item.name}{" "}
                    </label>
                  </span>))}
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <Col xs={12}>
                                <div className="Shape-tooltip">Symmetry
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Symmetry
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <div className="Fluorescence-color-filter-shop symmetry-flex">
                                {filterMainData.symmetry.length > 0 && filterMainData.symmetry.map((item, index) => (
                                    <span key={index}>
                    <input
                        type="checkbox"
                        name="symmetry"
                        value={item.name}
                        id={`symmetry-${item.name}`}
                        className="input-hidden"
                        checked={endUserFilter.symmetry.indexOf(item.name) != -1 ? true : false}
                        onChange={(e) => {

                            dispatch(endUserFilterAction({symmetry: IndFunctions.buttonGroupSlider(filterMainData.symmetry.map(i => i.name), endUserFilter.symmetry, item.name)}))

                        }}
                    />
                    <label
                        htmlFor={`symmetry-${item.name}`}
                        className="diamond-mr-20 group-right-0"
                    >
                      {item.name}
                    </label>
                  </span>))}
                            </div>
                        </Col>
                    </Row>
                    <Row className="row-bottom">
                        <Col xs={12} md={6}>
                            <Col xs={12}>
                                <div className="cut-tooltip">Table %
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Table %
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <div className="table-filter-shop">
                                {filterMainData.table && endUserFilter.table && (<>
                                        <Slider
                                            range
                                            defaultValue={[parseInt(100 / ((parseInt(filterMainData.table.max) - parseInt(filterMainData.table.min)) / parseInt(endUserFilter.table.max))), parseInt(100 / ((parseInt(filterMainData.table.max) - parseInt(filterMainData.table.min)) / parseInt(endUserFilter.table.min)))]}
                                            onAfterChange={(value) => {
                                                dispatch(endUserFilterAction({
                                                    table: {
                                                        min: functionGetValue(filterMainData.table.min, filterMainData.table.max, value[0]),
                                                        max: functionGetValue(filterMainData.table.min, filterMainData.table.max, value[1]),
                                                    }
                                                }))


                                            }}
                                        />
                                        <div className="amt-price-carat">
                                            <input
                                                type="number"
                                                name="min-table"
                                                id="min-table"
                                                className="input-hidden"
                                                value={endUserFilter.table.min ? endUserFilter.table.min : filterMainData.table.min}
                                                readOnly
                                            />
                                            <input
                                                type="number"
                                                name="max-table"
                                                id="max-table"
                                                className="input-hidden"
                                                value={endUserFilter.table.max ? endUserFilter.table.max : filterMainData.table.max}
                                                readOnly
                                            />
                                        </div>
                                    </>)}
                            </div>
                        </Col>
                        <Col xs={12} md={6}>
                            <Col xs={12}>
                                <div className="Shape-tooltip">Total Depth %
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Depth %
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <div className="depth-filter-shop">
                                {filterMainData.total_depth && endUserFilter.total_depth && (<>
                                        <Slider
                                            range
                                            tooltipVisible={false}
                                            defaultValue={[parseInt(100 / ((parseInt(filterMainData.total_depth.max) - parseInt(filterMainData.total_depth.min)) / parseInt(endUserFilter.total_depth.max))), parseInt(100 / ((parseInt(filterMainData.total_depth.max) - parseInt(filterMainData.total_depth.min)) / parseInt(endUserFilter.total_depth.min)))]}
                                            onAfterChange={(value) => {
                                                dispatch(endUserFilterAction({
                                                    total_depth: {
                                                        min: functionGetValue(filterMainData.total_depth.min, filterMainData.total_depth.max, value[0]),
                                                        max: functionGetValue(filterMainData.total_depth.min, filterMainData.total_depth.max, value[1]),
                                                    }
                                                }))
                                            }}
                                        />
                                        <div className="amt-price-carat">
                                            <input
                                                type="number"
                                                name="min-depth"
                                                id="min-depth"
                                                className="input-hidden"
                                                value={endUserFilter.total_depth.min ? endUserFilter.total_depth.min : filterMainData.total_depth.min}
                                                readOnly
                                            />
                                            <input
                                                type="number"
                                                name="max-depth"
                                                id="max-depth"
                                                className="input-hidden"
                                                value={endUserFilter.total_depth.max ? endUserFilter.total_depth.max : filterMainData.total_depth.max}
                                                readOnly
                                            />
                                        </div>
                                    </>)}
                            </div>
                        </Col>
                    </Row>
                    {/* <Row className="row-bottom">
          <Col xs={12} md={6}>
            <Col xs={12}>
              <div className="cut-tooltip">Length/Width Ratio
                <div className="tooltip-Shape">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                      fill="#262626"
                    />
                  </svg>
                  <div className="tooltip-content tooltip-bottom">
                    <div className="tooltip-shape-desc">
                      <div className="tooltip-shape-link">
                        <a href="#" className="readmore-carat">
                          Read more about diamond L/W Ratio
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <div className="ratio-filter-shop">
              {filterMainData.ratio && endUserFilter.ratio && (
                <>
                  <Slider
                    range
                    defaultValue={[1, 50]}
                    onAfterChange={(value) => {
                      dispatch(endUserFilterAction({
                        ratio: {
                          min: functionGetValue(filterMainData.ratio.min, filterMainData.ratio.max, value[0]),
                          max: functionGetValue(filterMainData.ratio.min, filterMainData.ratio.max, value[1]),
                        }
                      }))

                    }}
                  />
                  <input
                    type="number"
                    name="min-ratio"
                    id="min-ratio"
                    className="input-hidden"
                    value={endUserFilter.ratio.min ? endUserFilter.ratio.min : filterMainData.ratio.min}
                    readOnly
                  />
                  <input
                    type="number"
                    name="max-ratio"
                    id="max-ratio"
                    className="input-hidden"
                    value={endUserFilter.ratio.max ? endUserFilter.ratio.max : filterMainData.ratio.max}
                    readOnly
                  />
                </>
              )}
            </div>
          </Col>
          <Col xs={12} md={6}>
            <Col xs={12}>
              <div className="Shape-tooltip">Shade/Milky
                <div className="tooltip-Shape">
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                      fill="#262626"
                    />
                  </svg>
                  <div className="tooltip-content tooltip-bottom">
                    <div className="tooltip-shape-desc">
                      <div className="tooltip-shape-link">
                        <a href="#" className="readmore-carat">
                          Read more about diamond Shade/Milky
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <div className="Fluorescence-color-filter-shop shademilky-flex">
              {filterMainData.shademilky.length > 0 &&
                filterMainData.shademilky.map((item, index) => (
                  <span key={index}>
                    <input
                      type="radio"
                      name="symmetry-name"
                      id={`shademilky-${item.name}`}
                      className="input-hidden"
                    />
                    <label
                      htmlFor={`shademilky-${item.name}`}
                      className="diamond-mr-20 group-right-0"
                    >
                      {item.name}
                    </label>
                  </span>
                ))}
            </div>
          </Col>
        </Row> */}
                </div>
                <Row className="row-bottom">
                    <Col xs={12} md={6}>
                        <div className="filters-left">
                            <a
                                href="#"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setIsActive((current) => !current);
                                }}
                            >
                                {isActive ? "Less Filters" : "More Filters"}
                            </a>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <div className="filters-right text-end">
                            <a href="#" onClick={handleReset}>
                                Clear Filters
                            </a>
                        </div>
                    </Col>
                </Row>
                <Row className="row-bottom">
                    {productID == "independentDiamond" ? (<span>
          <input
              type="checkbox"
              name="pairing"
              checked={endUserFilter.earringSearch}
              id={`pairing`}
              className="input-hidden"
              onChange={(e) => {
                  if (e.target.checked) {
                      dispatch(addDiamondData([], 0))
                      setTimeout(() => {
                          dispatch(endUserFilterAction({earringSearch: true}))
                      }, 1000);
                  } else {
                      dispatch(addDiamondData([], 0))
                      setTimeout(() => {
                          dispatch(endUserFilterAction({earringSearch: false}))
                      }, 1000);

                  }
              }}
          />
          <label htmlFor={`pairing`} className="diamond-mr-20">
            <span>Search for Pairs For Earrings</span>
          </label>
        </span>) : false}

                </Row>
            </div>
            {/* desktop code end */}
            {/* Mobile code started */}

            <div className="mob-dev-on">
                <Button type="primary" onClick={() => setModal1Open(true)}>
                    Filters
                </Button>
                <Modal
                    open={modal1Open}
                    onOk={() => setModal1Open(false)}
                    onCancel={() => setModal1Open(false)}
                    className="side-filters"
                >
                    <Row className="row-bottom mobile-v-sep">
                        <Col xs={12}>
                            <div className="cut-tooltip">
                                Shape
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="image-all-shape">
                                            <img
                                                src={env.wp_media_path + alldiamondshap}
                                                className="full-image-all-shap"
                                                alt="grid-img"
                                            />
                                        </div>
                                        <div className="tooltip-shape-desc">
                                            Since all diamond shapes are very different, unique
                                            characteristics determine quality for each shape. A diamond
                                            of any shape can be selected based on your preferences and
                                            the design you are interested in.
                                            <br/>
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-shapes">
                                                    Read more about diamond shapes
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12}>
                            <div className="diamond-shape-filter-shop sep-bottom">
                                {/* <div className="diamond-mr-20"></div> */}
                                {filterMainData.shape ? filterMainData.shape.length > 0 && filterMainData.shape.map((item) => (
                                    <div key={item.name} className="diamond-mr-20 shape-fillter-select">
                                        <input
                                            type="checkbox"
                                            name="shape"
                                            checked={endUserFilter.shape.indexOf(item.name) != -1 ? true : false}
                                            id={`shape-${item.name}`}
                                            value={item.name}
                                            className="input-hidden"
                                            onChange={(e) => {
                                                if (e.target.checked) {
                                                    let temp = endUserFilter.shape;

                                                    temp.push(item.name);

                                                    dispatch(endUserFilterAction({shape: temp}))
                                                } else {
                                                    let temp = endUserFilter.shape;
                                                    for (var i = 0; i < temp.length; i++) {
                                                        if (temp[i] === item.name) {
                                                            temp.splice(i, 1);
                                                        }
                                                        dispatch(endUserFilterAction({shape: temp}))
                                                    }
                                                }

                                            }}
                                        />
                                        <label htmlFor={`shape-${item.name}`} className="mnshapo">
                                            <Image height="28px" width="28px" src={item.image} alt={item.name}/>
                                            <div><span>{item.name}</span></div>
                                        </label>
                                        {/* <span>{item.name}</span> */}
                                    </div>)) : false}
                            </div>
                        </Col>
                        <div class="h-divider">
                            <div class="shadow"></div>
                            <div class="text2 dd-none"><img
                                src="https://sarvada.codeperts.com/wp-content/uploads/2023/07/Group-2.png"/></div>
                        </div>
                        <Col xs={12}>
                            <div className="cut-tooltip">Price
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Price
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {filterMainData.price && endUserFilter.price && (<>
                                    <Slider
                                        range={true}
                                        step={null}
                                        tooltipVisible={false}
                                        value={[parseInt(100 / ((parseInt(filterMainData.price.max) - parseInt(filterMainData.price.min)) / parseInt(endUserFilter.price.max))), parseInt(100 / ((parseInt(filterMainData.price.max) - parseInt(filterMainData.price.min)) / parseInt(endUserFilter.price.min)))

                                        ]}
                                        onChange={(value) => {

                                            dispatch(endUserFilterAction({
                                                price: {
                                                    min: functionGetValue(parseInt(filterMainData.price.min), parseInt(filterMainData.price.max), value[0]),
                                                    max: functionGetValue(parseInt(filterMainData.price.min), parseInt(filterMainData.price.max), value[1]),
                                                }
                                            }))

                                        }}
                                    />
                                    <div className="price-inpt amt-price-carat">
                                        <input
                                            type="number"
                                            name="min-price"
                                            id="min-price"
                                            className="input-hidden"
                                            value={endUserFilter.price.min ? endUserFilter.price.min : filterMainData.price.min}
                                            readOnly
                                        />
                                        <input
                                            type="number"
                                            name="max-price"
                                            id="max-price"
                                            className="input-hidden"
                                            value={endUserFilter.price.max ? endUserFilter.price.max : filterMainData.price.max}
                                            readOnly
                                        />
                                    </div>
                                </>)}
                        </Col>
                    </Row>
                    <div class="h-divider">
                        <div class="shadow"></div>
                        <div class="text2 dd-none"><img
                            src="https://sarvada.codeperts.com/wp-content/uploads/2023/07/Group-2.png"/></div>
                    </div>
                    <Row className="row-bottom">
                        <Col xs={12}>
                            <Col xs={12}>
                                <div className="cut-tooltip">Carat
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Carat
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="carat-filter-shop">
                                    {filterMainData.carat && (<>
                                            <Slider
                                                range={true}
                                                step={0.01}
                                                value={[parseFloat(100 / ((parseFloat(filterMainData.carat.max) - parseFloat(filterMainData.carat.min)) / parseFloat(endUserFilter.carat.max))), parseFloat(100 / ((parseFloat(filterMainData.carat.max) - parseFloat(filterMainData.carat.min)) / parseFloat(endUserFilter.carat.min)))]}

                                                tooltipVisible={false}
                                                onChange={(value) => {
                                                    const Fmax = parseFloat(filterMainData.carat.max),
                                                        Fmin = parseFloat(filterMainData.carat.min),
                                                        V1 = parseFloat(value[0]), V2 = parseFloat(value[1]);


                                                    dispatch(endUserFilterAction({
                                                        carat: {
                                                            min: ((V1 / 100) * (Fmax - Fmin) + Fmin).toFixed(2),
                                                            max: ((V2 / 100) * (Fmax - Fmin) + Fmin).toFixed(2),
                                                        }
                                                    }))
                                                }}
                                            />
                                            <div className="inp-carat">
                                                <input
                                                    type="number"
                                                    name="min-price"
                                                    id="min-price"
                                                    className="input-hidden"
                                                    value={endUserFilter.carat.min ? endUserFilter.carat.min : filterMainData.carat.min}
                                                    onChange={e => {
                                                        dispatch(endUserFilterAction({
                                                            carat: {
                                                                min: e.target.value, max: endUserFilter.carat.max,
                                                            }
                                                        }))
                                                    }}
                                                />
                                                <input
                                                    type="number"
                                                    name="max-price"
                                                    id="max-price"
                                                    className="input-hidden"
                                                    value={endUserFilter.carat.max ? endUserFilter.carat.max : filterMainData.carat.max}
                                                    onChange={e => {
                                                        dispatch(endUserFilterAction({
                                                            carat: {
                                                                min: endUserFilter.carat.min, max: e.target.value,
                                                            }
                                                        }))
                                                    }}
                                                />
                                            </div>
                                        </>)}
                                </div>
                            </Col>
                        </Col>
                    </Row>
                    <div class="h-divider">
                        <div class="shadow"></div>
                        <div class="text2 dd-none"><img
                            src="https://sarvada.codeperts.com/wp-content/uploads/2023/07/Group-2.png"/></div>
                    </div>
                    <Row className="row-bottom">
                        <Col xs={12}>
                            <Col xs={12}>
                                <div className="cut-tooltip">Cut
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Cut
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <Slider
                                    range={true}
                                    marks={marks.cut(filterMainData.cut)}
                                    step={null}
                                    value={[(parseInt(100 / (filterMainData.cut.length - 1))) * filterMainData.cut.map(i => i.name).indexOf(endUserFilter.cut.max), (parseInt(100 / (filterMainData.cut.length - 1))) * filterMainData.cut.map(i => i.name).indexOf(endUserFilter.cut.min)]}
                                    tooltipVisible={false}
                                    onChange={(value) => {

                                        dispatch(endUserFilterAction({
                                            cut: {
                                                min: filterMainData.cut[parseInt(value[0] / (parseInt(100 / (filterMainData.cut.length - 1))))].name,
                                                max: filterMainData.cut[parseInt(value[1] / (parseInt(100 / (filterMainData.cut.length - 1))))].name,
                                            }
                                        }))


                                    }}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <div class="h-divider">
                        <div class="shadow"></div>
                        <div class="text2 dd-none"><img
                            src="https://sarvada.codeperts.com/wp-content/uploads/2023/07/Group-2.png"/></div>
                    </div>
                    {state.diamondReducer.colorTab == "color" && (<Row className="row-bottom">
                        <Col xs={12}>
                            <Col xs={12}>
                                <div className="Shape-tooltip">
                                    <div className="color-btn">Color / <button onClick={e => {
                                        dispatch(changeColor("fancy"))
                                    }} className="brn-none">Fancy Colored Diamonds</button></div>
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Color
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Row>
                                <div className="diamond-color-filter-shop color-mob">
                                    {fancy_color && fancy_color_HTML}
                                </div>
                            </Row>
                        </Col>

                    </Row>)}
                    {/* <div class="h-divider">
          <div class="shadow"></div>
          <div class="text2 dd-none"><img src="https://sarvada.codeperts.com/wp-content/uploads/2023/07/Group-2.png" /></div>
        </div> */}
                    <Row className="row-bottom">
                        <Col xs={12}>
                            <Col xs={12}>
                                <div className="cut-tooltip">Clarity
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Clarity
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Slider
                                range={true}
                                marks={marks.clarity(filterMainData.clarity)}
                                step={null}
                                value={[(parseInt(100 / (filterMainData.clarity.length - 1))) * filterMainData.clarity.map(i => i.name).indexOf(endUserFilter.clarity.max), (parseInt(100 / (filterMainData.clarity.length - 1))) * filterMainData.clarity.map(i => i.name).indexOf(endUserFilter.clarity.min)]}
                                tooltipVisible={false}
                                onChange={(value) => {
                                    console.log(parseInt(value[0] / (parseInt(100 / (filterMainData.clarity.length - 1)))))
                                    dispatch(endUserFilterAction({
                                        clarity: {
                                            min: filterMainData.clarity[parseInt(value[0] / (parseInt(100 / (filterMainData.clarity.length - 1))))].name,
                                            max: filterMainData.clarity[parseInt(value[1] / (parseInt(100 / (filterMainData.clarity.length - 1))))].name,
                                        }
                                    }))


                                }}
                            />

                        </Col>
                    </Row>
                    {state.diamondReducer.colorTab == "fancy" && (<Row className="row-bottom">
                        <Col xs={12}>
                            <Col xs={12}>
                                <div className="Shape-tooltip">
                                    <button onClick={e => {
                                        dispatch(changeColor("color"))
                                    }}>Color
                                    </button>
                                    / Fancy Colored Diamonds
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Color
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="certificate-color-filter-shop intentgrid colored-grid">
                                    {filterMainData.fancy_diamond_color.length > 0 && filterMainData.fancy_diamond_color.map((item, index) => (
                                        <div key={index}>
                                            <input
                                                type="checkbox"
                                                name={`fancy_diamond_color`}
                                                checked={endUserFilter.fancy_diamond_color.map(i => i.Name).indexOf(item.Name) != -1 ? true : false}
                                                id={`fancy_diamond_color-${item.Name}`}
                                                className="input-hidden"
                                                onChange={(e) => {
                                                    console.log(e.target.checked, item)
                                                    if (e.target.checked) {
                                                        let temp = endUserFilter.fancy_diamond_color;
                                                        temp.push(item);
                                                        dispatch(endUserFilterAction({fancy_diamond_color: temp}))
                                                    } else {
                                                        const temp = endUserFilter.fancy_diamond_color;
                                                        for (var i = 0; i < temp.length; i++) {
                                                            if (temp[i].Name === item.Name) {
                                                                temp.splice(i, 1);
                                                            }
                                                            dispatch(endUserFilterAction({fancy_diamond_color: temp}))

                                                        }
                                                    }
                                                }}
                                            />
                                            <label
                                                htmlFor={`fancy_diamond_color-${item.Name}`}
                                                className="diamond-mr-22 group-right-0">
                                                {item.Name}
                                            </label>
                                        </div>

                                    ))}

                                </div>
                            </Col>
                        </Col>
                    </Row>)}
                    {state.diamondReducer.colorTab == "fancy" && (<Row className="row-bottom">
                        <Col xs={12}>
                            <Col xs={12}>
                                <div className="Shape-tooltip">Intensity
                                    <div className="tooltip-Shape">
                                        <svg
                                            width="14"
                                            height="14"
                                            viewBox="0 0 14 14"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                fillRule="evenodd"
                                                clipRule="evenodd"
                                                d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                                fill="#262626"
                                            />
                                        </svg>
                                        <div className="tooltip-content tooltip-bottom">
                                            <div className="tooltip-shape-desc">
                                                <div className="tooltip-shape-link">
                                                    <a href="#" className="readmore-carat">
                                                        Read more about diamond Color
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Col>
                            <Col xs={12}>
                                <div className="certificate-color-filter-shop intentgrid itens-grid">
                                    {filterMainData.fancy_color_intensity.length > 0 && filterMainData.fancy_color_intensity.map((item, index) => (
                                        <span key={index}>
                  <input
                      type="checkbox"
                      name="fancy_color_intensity"
                      checked={endUserFilter.fancy_color_intensity.indexOf(item.Name) != -1 ? true : false}
                      id={`fancy_color_intensity-${item.Name}`}
                      className="input-hidden"
                      onChange={(e) => {

                          dispatch(endUserFilterAction({fancy_color_intensity: IndFunctions.buttonGroupSlider(filterMainData.fancy_color_intensity.map(i => i.Name), endUserFilter.fancy_color_intensity, item.Name)}))


                      }}
                  />
                  <label
                      htmlFor={`fancy_color_intensity-${item.Name}`}
                      className="diamond-mr-22 group-right-0"
                  >
                    {item.Name}
                  </label>
                </span>))}
                                </div>
                            </Col>
                        </Col>

                        {/* <Col xs={12} className="cleare">
          <Col xs={12}>
            <div className="cut-tooltip">Clarity
              <div className="tooltip-Shape">
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                    fill="#262626"
                  />
                </svg>
                <div className="tooltip-content tooltip-bottom">
                  <div className="tooltip-shape-desc">
                    <div className="tooltip-shape-link">
                      <a href="#" className="readmore-carat">
                        Read more about diamond Clarity
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12}>
          <Slider
            range={true}
            marks={marks.clarity(filterMainData.clarity)}
            step={null}
            value={[
              (parseInt(100 / (filterMainData.clarity.length - 1))) * filterMainData.clarity.map(i => i.name).indexOf(endUserFilter.clarity.max),
              (parseInt(100 / (filterMainData.clarity.length - 1))) * filterMainData.clarity.map(i => i.name).indexOf(endUserFilter.clarity.min)
            ]}
            tooltipVisible={false}
            onChange={(value) => {
              console.log(parseInt(value[0] / (parseInt(100 / (filterMainData.clarity.length - 1)))))
              dispatch(endUserFilterAction({
                clarity: {
                  min: filterMainData.clarity[parseInt(value[0] / (parseInt(100 / (filterMainData.clarity.length - 1))))].name,
                  max: filterMainData.clarity[parseInt(value[1] / (parseInt(100 / (filterMainData.clarity.length - 1))))].name,
                }
              }))


            }}
          />
          </Col>
        </Col> */}
                    </Row>)}
                    <div class="h-divider">
                        <div class="shadow"></div>
                        <div class="text2 dd-none"><img
                            src="https://sarvada.codeperts.com/wp-content/uploads/2023/07/Group-2.png"/></div>
                    </div>
                    <Row className="row-bottom">
                        <Col xs={12}>
                            <div className="Shape-tooltip">Fluorescence
                                <div className="tooltip-Shape">
                                    <svg
                                        width="14"
                                        height="14"
                                        viewBox="0 0 14 14"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M0 7C0 10.866 3.13401 14 7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7ZM13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7ZM7.75 3.25C7.75 3.66421 7.41421 4 7 4C6.58579 4 6.25 3.66421 6.25 3.25C6.25 2.83579 6.58579 2.5 7 2.5C7.41421 2.5 7.75 2.83579 7.75 3.25ZM7 11.5C6.58579 11.5 6.25 11.1642 6.25 10.75V6.25C6.25 5.83579 6.58579 5.5 7 5.5C7.41421 5.5 7.75 5.83579 7.75 6.25V10.75C7.75 11.1642 7.41421 11.5 7 11.5Z"
                                            fill="#262626"
                                        />
                                    </svg>
                                    <div className="tooltip-content tooltip-bottom">
                                        <div className="tooltip-shape-desc">
                                            <div className="tooltip-shape-link">
                                                <a href="#" className="readmore-carat">
                                                    Read more about diamond Fluorescence
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} className="b-bottom">
                            <div className="Fluorescence-color-filter-shop fluoesence-grid">
                                {filterMainData.fluorescence.length > 0 && filterMainData.fluorescence.map((item, index) => (
                                    <div key={index}>
                                        <input
                                            type="checkbox"
                                            name={`fluorescence`}
                                            checked={endUserFilter.fluorescence.indexOf(item.name) != -1 ? true : false}
                                            id={`fluorescence-${item.name}`}
                                            className="input-hidden"
                                            onChange={(e) => {

                                                dispatch(endUserFilterAction({fluorescence: IndFunctions.buttonGroupSlider(filterMainData.fluorescence.map(i => i.name), endUserFilter.fluorescence, item.name)}))

                                            }}
                                        />
                                        <label
                                            htmlFor={`fluorescence-${item.name}`}
                                            className="diamond-mr-20 group-right-0"
                                        >
                                            {item.name}
                                        </label>

                                    </div>))}
                            </div>
                        </Col>
                    </Row>
                </Modal>
            </div>


        </>) : (<Image src={env.wp_media_path + loaderImgUrl} alt="Filter Loading..."/>);
}
