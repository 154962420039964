import React from "react";
import env from '../../env.json';
import { Image } from "react-bootstrap";
import round from '../../images/carat-round.png';
import princess from '../../images/carat-princess.png';
import pear from '../../images/carat-pear.png';
import oval from '../../images/carat-oval.png';
import heart from '../../images/carat-heart.png';
import marquise from '../../images/carat-marquise.png';
import emeraid from '../../images/carat-emeraid.png';
import cushion from '../../images/carat-cushion.png';
import asscher from '../../images/carat-asscher.png';
import radiant from '../../images/carat-radiant.png';

const CaratImage = ({shape}) => {

    let urlName = "";

    switch ( shape ) {
        case "Round":
            urlName = round;
            break;
        case "Princess":
            urlName = princess
            break;
        case "Cushion Modified":
            urlName = cushion
            break;
        case "Oval":
            urlName = oval
            break;
        case "Emerald":
            urlName = emeraid
            break;
        case "Pear":
            urlName = pear
            break;
        case "Asscher":
            urlName = asscher
            break;
        case "Heart":
            urlName = heart
            break;
        case "Radiant":
            urlName = radiant
            break;
        case "Marquise":
            urlName = marquise
            break;
        default:
    }

    return(
        <Image src={env.wp_media_path+urlName} />
    );
}

export default CaratImage;