
import { useEffect, useState } from "react";
import Table from "./tables/earringTable";
import Compare from "./compare/earringCompare";
import { Row, Col } from "react-bootstrap";
import env from "../../env.json";
import ModifyColumns from "./tables/modifyColumns";
import Wishlist from "./wishlist/wishlist-earring";
import { loadWishListEarring } from "../../redux/actions/diamondAction";
import { useSelector, useDispatch } from "react-redux";
import IndFunctions from "../common/IndFunctions";
export default function EarringListing({ onSort }) {
    const [tabActive, setTabActive] = useState("All");
    const [listingStyle, setlistingStyle] = useState("table");

    const [comparedData, setCompareData] = useState([]);
    const state = useSelector((state) => state);
    const dispatch = useDispatch();


    let ActiveView;
    if (tabActive === "All") {
        ActiveView = (
            <Table
                tableStyle={listingStyle}
            ></Table>
        );
    } else if (tabActive === "Compare") {
        ActiveView = <Compare comparedData={comparedData}></Compare>;
    } else {
        ActiveView = <Wishlist></Wishlist>;
    }

    function getClass(ac) {
        let c = "nav-link";
        if (ac === tabActive) {
            c += " active";
        }
        return c;
    }
    function getTableLayoutClass(ac) {
        let c = "view-btn ";
        if (ac === "accordian") {
            c += " grid-view-button ";
        } else {
            c += "list-view-button ";
        }
        if (listingStyle === ac) {
            c += "active";
        }
        return c;
    }
    useEffect(() => {
        IndFunctions.wishlistAPI(env.getEarringWishlist, {}, res => {
            if (res.data.success) {
                dispatch(loadWishListEarring((res.data)))
            }
        })
    })
    return (<>
        <div className="shop-conflict-free">
            <div className="container">

                <Row className="row-bottom">
                    <Col xs={6}>
                        <h3 className="third-heading">
                            Shop Conflict-free, Natural Diamonds
                        </h3>
                    </Col>

                    <Col xs={6}>
                        <div className="filter-buttons">
                            <button
                                className={getTableLayoutClass("accordian")}
                                onClick={(e) => setlistingStyle("table")}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M8 6H21"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8 12H21"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M8 18H21"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M3 6H3.01"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M3 12H3.01"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M3 18H3.01"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                            <button
                                className={getTableLayoutClass("table")}
                                onClick={(e) => setlistingStyle("accordian")}
                            >
                                <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M10 3H3V10H10V3Z"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M21 3H14V10H21V3Z"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M21 14H14V21H21V14Z"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                    <path
                                        d="M10 14H3V21H10V14Z"
                                        stroke="#3A3A3A"
                                        strokeWidth="2"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                    />
                                </svg>
                            </button>
                        </div>
                    </Col>
                </Row>
                <Row className="fillters-items">
                    <Col xs={6}>
                        <ul className="nav nav-tabs shop-external">
                            <li className="nav-item">
                                <a href="#"
                                    className={getClass("All")}
                                    aria-current="page"
                                    onClick={(e) => {
                                        setTabActive("All");
                                        setCompareData([])
                                    }}
                                >
                                    All Diamonds
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={getClass("Compare")}
                                    aria-current="page"
                                    href="#"
                                    onClick={(e) => setTabActive("Compare")}
                                >
                                    Compare ({state.diamondReducer.comparisonEarring.length > 0 ? state.diamondReducer.comparisonEarring.length : 0})
                                </a>
                            </li>
                            <li className="nav-item">
                                <a
                                    className={getClass("Whishlist")}
                                    aria-current="page"
                                    href="#"
                                    onClick={(e) => setTabActive("Whishlist")}
                                >
                                    Wish list
                                </a>
                            </li>
                        </ul>
                    </Col>
                    <Col xs={6}>
                        <Row>
                            <Col md={{ span: 6, offset: 6 }}>
                                <ModifyColumns />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <div>{ActiveView}</div>
            </div>
        </div>
    </>);
}
