import React, { useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { addComparisionEarring, removeComparisionEarring } from "../../../redux/actions/diamondAction";
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import gridimage from "../../../images/demo-diamond-grid.jpg";
import env from "../../../env.json";

export default function DiamondFinder() {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const { productID } = useParams();
    const navigate = useNavigate();
  
    return (<Row className="GridView">
        {state.diamondReducer.diamonds && state.diamondReducer.diamonds.map((diamond, index) => (
            <Col xs={3} key={index} >
                <div className="box compare">
                    <div className="compare-wishlist">
                        <input
                            type="checkbox"
                            name="compare"
                            checked={state.diamondReducer.comparisonEarring.filter(item => item.one.diamond_id === diamond.one.diamond_id).length > 0 ? true : false}
                            onClick={e => e.target.checked === true ? dispatch(addComparisionEarring((diamond))) : dispatch(removeComparisionEarring((diamond)))}
                            disabled={state.diamondReducer.comparisonEarring.length > 4  && state.diamondReducer.comparisonEarring.filter(item => item.one.diamond_id === diamond.one.diamond_id).length === 0}
                        />
                    </div>
                    <div onClick={() => {
                        navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
                    }}> first diamond
                        <Image src={diamond.one.image_file_url ? diamond.one.image_file_url : env.wp_media_path + gridimage} alt="" />
                        <h5>{diamond.one.size && `${diamond.one.size} Carat`} {diamond.one.clarity && `| ${diamond.one.clarity}`}</h5>
                        <p>
                            {diamond.one.cut && `${diamond.one.cut} Cut`} {diamond.one.fluor_intensity && `${diamond.one.fluor_intensity} Fluorescence`} {diamond.one.lab && `${diamond.one.lab} certified`} {diamond.one.shape && ` | ${diamond.one.shape} Diamond`}
                        </p>
                        <h6>
                            {diamond.one.total_sales_price_in_currency && ` ${diamond.one.total_sales_price_in_currency}`} {diamond.one.total_sales_price_in_currency && <strike>$ {diamond.one.total_sales_price_in_currency}</strike>}
                        </h6>

                    </div>
                    <div onClick={() => {
                        navigate(`/earring-show/${diamond.one.diamond_id}/${diamond.two.diamond_id}/${productID}`)
                    }}>second diamond
                        <Image src={diamond.two.image_file_url ? diamond.two.image_file_url : env.wp_media_path + gridimage} alt="" />
                        <h5>{diamond.two.size && `${diamond.two.size} Carat`} {diamond.two.clarity && `| ${diamond.two.clarity}`}</h5>
                        <p>
                            {diamond.two.cut && `${diamond.two.cut} Cut`} {diamond.two.fluor_intensity && `${diamond.two.fluor_intensity} Fluorescence`} {diamond.two.lab && `${diamond.two.lab} certified`} {diamond.two.shape && ` | ${diamond.two.shape} Diamond`}
                        </p>
                        <h6>
                            {diamond.two.total_sales_price_in_currency && ` ${diamond.two.total_sales_price_in_currency}`} {diamond.two.total_sales_price_in_currency && <strike>$ {diamond.two.total_sales_price_in_currency}</strike>}
                        </h6>
                    </div>

                </div>
            </Col>
        ))}
    </Row>);
}
