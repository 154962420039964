import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector, useDispatch } from "react-redux";
import { addCustomDesignRingValues, removeCustomDesignRingValues } from "../../redux/actions/diamondAction";
export default function WhoWill() {
    const state = useSelector((state) => state);
    const dispatch = useDispatch();
    const metal = [{ name: "White Gold" }, { name: "Rose Gold" }, { name: "Yellow Gold" }, { name: "Platinum" }];
    const metalPurity = [{ name: "18K" }, { name: "14K" }, { name: "Platium 950" }];
    const metalHTML = metal.map((i, index) => (<span key={index}>
        <input
            type="radio"
            name="carat"
            value={i.name}
            checked={state.customDesign.metal == i.name}
            id={`carat-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(addCustomDesignRingValues({ metal: i.name }))
                } else {
                    dispatch(removeCustomDesignRingValues({ metal: i.name }))
                }
            }}
        />
        <label
            htmlFor={`carat-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract color-shappire"
        >
            <i class="circle-color color-gold"></i>{i.name}
        </label>
    </span>));
    const metalPurityHTML = metalPurity.map((i, index) => (<span key={index}>
        <input
            type="radio"
            name="colorRange"
            value={i.name}
            checked={state.customDesign.metalColor == i.name}
            id={`colorRange-${i.name}`}
            className="input-hidden"
            onChange={(e) => {
                if (e.target.checked) {
                    dispatch(addCustomDesignRingValues({ metalColor: i.name }))
                } else {
                    dispatch(removeCustomDesignRingValues({ metalColor: i.name }))
                }
            }}
        />
        <label
            htmlFor={`colorRange-${i.name}`}
            className="diamond-mr-20 group-right-0 border-ract"
        >
            {i.name}
        </label>
    </span>));

    return (
        <>
            <Row>
                <Col>
                <div className="text-center">
                    <h1>Choose Metal</h1>
                </div>  
                </Col>

            </Row>
            <Row><Col md={12}> <div className="inner-label">Metal</div></Col>
                <Col md={12}><div className="shape-selct-diamond text-center">
                {metalHTML}
                    </div>
                </Col>
            </Row>
            <Row> <Col md={12}> <div className="inner-label">Metal Purity</div></Col>
            <Col md={12}><div className="shape-selct-diamond text-center">
            {metalPurityHTML}
                    </div>                    
                </Col>
            </Row>
            <Row>
                <span >
                    <input
                        type="radio"
                        name="shape"
                        checked={state.customDesign.metalColor == "notSure"}
                        id={`notSure`}
                        className="input-hidden"
                        onChange={(e) => {
                            if (e.target.checked) {
                                dispatch(addCustomDesignRingValues({ metal: "notSure" }))
                                dispatch(addCustomDesignRingValues({ metalColor: "notSure" }))
                            } else {
                                dispatch(removeCustomDesignRingValues({ metal: "notSure" }))
                                dispatch(removeCustomDesignRingValues({ metalColor: "notSure" }))
                            }
                        }}
                    />
                    <label htmlFor={`notSure`} className="diamond-mr-20">

                        <span>Not Sure</span>
                    </label>
                </span>
            </Row>
        </>
    );
}
