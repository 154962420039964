import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Slider } from 'antd';

const QuizPrice = ({ onStateChange }) => {
    const state = useSelector((state) => state);
    function functionGetValue(APIMin, APIMax, sliderValue) {
        return (sliderValue / 100) * (APIMax - APIMin) + APIMin;
    }
    const [price, setPrice] = useState({});



    return (
        <div className="spend-sec">
        <ul id="progresssteps">
            <li class="active"></li>
            <li class="active"></li>
            <li></li>
            <li></li>
	        </ul>
        <div className="diamond-price-filter-shop m-top-60">
            <h4 className="quiz-heading">How much do you want to spend?</h4>
            <div className="slide-spend">
            <Slider
                range={true}
                step={10}
                tooltipVisible={false}
                onChange={(value) => {
                    setPrice({
                        min: functionGetValue(parseInt(state.diamondReducer.filterMainData.price.min), parseInt(state.diamondReducer.filterMainData.price.max), value[0]),
                        max: functionGetValue(parseInt(state.diamondReducer.filterMainData.price.min), parseInt(state.diamondReducer.filterMainData.price.max), value[1]),
                    });
                    onStateChange({
                        min: functionGetValue(parseInt(state.diamondReducer.filterMainData.price.min), parseInt(state.diamondReducer.filterMainData.price.max), value[0]),
                        max: functionGetValue(parseInt(state.diamondReducer.filterMainData.price.min), parseInt(state.diamondReducer.filterMainData.price.max), value[1]),
                    });

                }}
            /></div>
            <div className="dis-input-price">
                <div className="price-input">
                    <span className="currency-symbol">Rs</span>
                    <input
                        type="number"
                        name="min-price"
                        id="min-price"
                        className="input-hidden width-fixes"
                        disabled={true}
                        placeholder="00"
                        value={parseInt(price.min)}
                        readOnly
                    />
                </div>
                <div className="price-input">
                    <span className="currency-symbol">Rs</span>
                    <input
                        type="number"
                        name="max-price"
                        id="max-price"
                        className="input-hidden width-fixes"
                        disabled={true}
                        placeholder="00"
                        value={parseInt(price.max)}
                        readOnly
                    />
                </div>
            </div>
        </div>
        </div>
    );
}

export default QuizPrice;